export const characters = {
  action: { name: "Action" },
  peter: { name: "Peter Talisman", image: "assets/portraits/peter.png" },
  arthur: {
    name: "Arthur Portal-Dolmen",
    image: "assets/portraits/arthur-2.jpg",
  },
  jessica: { name: "Jessica Ovaltine", image: "assets/portraits/jessica.jpeg" },
  john: { name: "John Ovaltine", image: "assets/portraits/oldjohn.jpeg" },
  roger: { name: "Roger Mandrake", image: "assets/portraits/roger.jpeg" },
  billy: { name: "Billy Bicklesford", image: "assets/portraits/billy.jpeg" },
  gorse: { name: "Gorse Panshawe", image: "assets/portraits/gorse.jpeg" },
  tim: { name: "Tim Wheatsheaf", image: "assets/portraits/tim.jpeg" },
  keith: { name: "Keith Demon", image: "assets/portraits/keith.jpeg" },
  sirflambard: {
    name: "Sir Flambard de Quincy",
    image: "assets/portraits/flambard.jpeg",
  },
}
