import { Vector2 } from "three"

export const rectangleEdge = (size: Vector2, includeCorners = true) => {
  const point = new Vector2()
  const points = []
  const cornerSize = includeCorners ? 0 : 1

  // top edge
  for (
    point.x = cornerSize, point.y = 0;
    point.x < size.x - cornerSize;
    point.x++
  )
    points.push(point.clone())
  //right edge
  for (
    point.x = size.x - 1, point.y = 1;
    point.y < size.y - cornerSize;
    point.y++
  )
    points.push(point.clone())
  // bottom edge
  for (
    point.x = size.x - 2, point.y = size.y - 1;
    point.x >= cornerSize;
    point.x--
  )
    points.push(point.clone())
  //left edge
  for (point.x = 0, point.y = size.y - 2; point.y > 0; point.y--)
    points.push(point.clone())

  return points
}

export const getNeighboursInRadius = (
  radius: number,
  includeCorners = true
) => {
  const size = new Vector2()
  const offset = new Vector2()
  const points: Vector2[] = []
  for (let r = 1; r <= radius; r++) {
    size.set(r * 2 + 1, r * 2 + 1)
    offset.set(-r, -r)
    const ring = rectangleEdge(size, includeCorners || r < radius)
    ring.forEach(point => point.add(offset))
    points.push(...ring)
  }
  return points
}

export const getNeighboursInRadiusCircular = (radius: number) => {
  const radiusSq = radius ** 2
  return getNeighboursInRadius(radius).filter(v => v.lengthSq() <= radiusSq)
}

// https://www.hashbangcode.com/article/drawling-circle-pixels-php
export const getCircleEdge = (radius: number) => {
  const points: Vector2[] = []
  let x = 0
  let y = radius
  let d = 3 - 2 * radius
  while (x <= y) {
    points.push(new Vector2(x, y))
    if (x !== 0) points.push(new Vector2(-x, y))
    if (y !== 0) {
      points.push(new Vector2(x, -y))
      if (x !== 0) points.push(new Vector2(-x, -y))
    }
    if (x !== y) {
      points.push(new Vector2(y, x))
      if (x !== 0) points.push(new Vector2(y, -x))
      if (y !== 0) {
        points.push(new Vector2(-y, x))
        if (x !== 0) points.push(new Vector2(-y, -x))
      }
    }
    x++
    if (d < 0) {
      d = d + 4 * x + 6
    } else {
      d = d + 4 * (x - y) + 10
      y--
    }
  }
  return points
}

export const orthogonalNeighbours = getNeighboursInRadius(1, false)
export const neighbours = getNeighboursInRadius(1, true)
