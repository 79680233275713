import {
  dialogue_Beacon,
  dialogue_ADifficultFog,
  dialogue_ALifeChangingDiscovery,
  dialogue_WeHaveToLeaveThisTown,
  dialogue_LiveFromCleyHill,
  dialogue_RestEasy,
  dialogue_HahaFuckingQuest,
  dialogue_OneForTheRoad,
  dialogue_WelcomeHomePeter,
  dialogue_TheAbsoluteScene,
  dialogue_LeyUDown,
  dialogue_DanceOfTheEquinox,
  dialogue_Outro,
  dialogue_Epilogue,
} from "."

export type MessageConfig = {
  text: string
  image?: string
  isLore?: boolean
  character: { name: string; image?: string }
}
export type DialogueConfig = { title: string; messages: MessageConfig[] }

export const dialogues = {
  chapters: [
    dialogue_ADifficultFog,
    dialogue_ALifeChangingDiscovery,
    dialogue_WeHaveToLeaveThisTown,
    dialogue_LiveFromCleyHill,
    dialogue_RestEasy,
    dialogue_HahaFuckingQuest,
    dialogue_OneForTheRoad,
    dialogue_WelcomeHomePeter,
    dialogue_TheAbsoluteScene,
    dialogue_LeyUDown,
    dialogue_DanceOfTheEquinox,
  ],
  beacon: dialogue_Beacon,
  outro: dialogue_Outro,
  epilogue: dialogue_Epilogue,
}
