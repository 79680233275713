import { Color, Vector3 } from "three"
import { Spring, SpringConfig } from "wobble"
import { mapObject } from "./object"

export type ValuelessSpringConfig = Omit<SpringConfig, "fromValue" | "toValue">
export type SpringConfigWithValue<Value> = ValuelessSpringConfig & {
  fromValue: Value
  toValue: Value
}

type SpringGroupConfig<Key extends string> = SpringConfigWithValue<{
  [k in Key]: number
}>

const extractSpringConfig = <Key extends string>(
  config: Partial<SpringGroupConfig<Key>>,
  key: Key
): Partial<SpringConfig> => {
  const result = { ...config } as Partial<SpringConfig>
  if (config.fromValue) result.fromValue = config.fromValue[key]
  if (config.toValue) result.toValue = config.toValue[key]
  return result
}

type RequireOnly<T, K extends keyof T> = Partial<T> & Pick<T, K>

export class SpringGroup<Key extends string> {
  springs: Record<Key, Spring>
  constructor(config: RequireOnly<SpringGroupConfig<Key>, "fromValue">) {
    this.springs = mapObject(
      config.fromValue,
      (fromValue, key) => new Spring(extractSpringConfig(config, key))
    )
  }
  updateConfig(config: Partial<SpringGroupConfig<Key>>) {
    let key: Key
    for (key in this.springs)
      this.springs[key].updateConfig(extractSpringConfig(config, key))
    return this
  }
  start() {
    let key: Key
    for (key in this.springs) this.springs[key].start()
    return this
  }
  stop() {
    let key: Key
    for (key in this.springs) this.springs[key].stop()
    return this
  }
  get currentValue() {
    return mapObject(this.springs, spring => spring.currentValue)
  }
  removeAllListeners() {
    let key: Key
    for (key in this.springs) this.springs[key].removeAllListeners()
    return this
  }
}

export class ColorSpring extends SpringGroup<"r" | "g" | "b"> {
  color: Color
  constructor(config: RequireOnly<SpringConfigWithValue<Color>, "fromValue">) {
    super({
      ...config,
      fromValue: {
        r: config.fromValue.r,
        g: config.fromValue.g,
        b: config.fromValue.b,
      },
      toValue: config.toValue
        ? {
            r: config.toValue.r,
            g: config.toValue.g,
            b: config.toValue.b,
          }
        : config.fromValue,
    })
    this.color = new Color(
      config.fromValue.r,
      config.fromValue.g,
      config.fromValue.b
    )
    this.springs.r.onUpdate(() => (this.color.r = this.springs.r.currentValue))
    this.springs.g.onUpdate(() => (this.color.g = this.springs.g.currentValue))
    this.springs.b.onUpdate(() => (this.color.b = this.springs.b.currentValue))
  }
}

export class Vector3Spring extends SpringGroup<"x" | "y" | "z"> {
  vector: Vector3
  constructor(
    config: RequireOnly<SpringConfigWithValue<Vector3>, "fromValue">
  ) {
    super({
      ...config,
      fromValue: {
        x: config.fromValue.x,
        y: config.fromValue.y,
        z: config.fromValue.z,
      },
      toValue: config.toValue
        ? {
            x: config.toValue.x,
            y: config.toValue.y,
            z: config.toValue.z,
          }
        : config.fromValue,
    })
    this.vector = new Vector3(
      config.fromValue.x,
      config.fromValue.y,
      config.fromValue.z
    )
    this.springs.x.onUpdate(() => (this.vector.x = this.springs.x.currentValue))
    this.springs.y.onUpdate(() => (this.vector.y = this.springs.y.currentValue))
    this.springs.z.onUpdate(() => (this.vector.z = this.springs.z.currentValue))
  }
}
