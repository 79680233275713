import { useContext, createContext, useState, ReactNode, useMemo } from "react"
import { Texture, UniformsUtils, Vector2 } from "three"
import { defaultWeatherUniforms, WeatherUniforms } from "./WeatherMaterial"
import { useFrame, useThree } from "@react-three/fiber"
import { useStore } from "../StoreProvider"
import { useWindRenderer } from "./useWindRenderer"

const WeatherContext = createContext<{
  uniforms: WeatherUniforms
  windTexture: Texture
} | null>(null)

export const useWeather = () => {
  const value = useContext(WeatherContext)
  if (value === null)
    throw new Error("useWeather called outside of WeatherProvider")
  return value.uniforms
}

export const useWindTexture = () => {
  const value = useContext(WeatherContext)
  if (value === null)
    throw new Error("useWeather called outside of WeatherProvider")
  return value.windTexture
}

type WeatherProviderProps = {
  mapSize: Vector2
  fogMultiplier?: number
  children: ReactNode
}
export const WeatherProvider = ({
  mapSize,
  fogMultiplier = 1,
  children,
}: WeatherProviderProps) => {
  const rootStore = useStore()
  const weather = rootStore.weather
  const { camera, size } = useThree()
  const [uniforms] = useState<WeatherUniforms>(() => {
    const uniforms = UniformsUtils.clone(defaultWeatherUniforms)
    // non-number uniforms can be set once
    uniforms.viewMatrixInverse.value = camera.matrixWorld
    uniforms.projectionMatrixInverse.value = camera.projectionMatrixInverse
    uniforms.fog.value.color = weather.fogColor.color
    return uniforms
  })
  useFrame(() => {
    uniforms.viewMatrixInverse.value = camera.matrixWorld
    uniforms.projectionMatrixInverse.value = camera.projectionMatrixInverse
    uniforms.viewSize.value.set(size.width, size.height)
    uniforms.time.value = rootStore.time
    uniforms.rain.value = weather.rain.currentValue
    uniforms.fog.value.height = weather.fogHeight.currentValue
    uniforms.fog.value.density = weather.fogDensity.currentValue * fogMultiplier
    uniforms.wet.value = weather.wet
  })
  const windTexture = useWindRenderer(mapSize)
  const value = useMemo(
    () => ({ uniforms, windTexture }),
    [uniforms, windTexture]
  )
  return (
    <WeatherContext.Provider value={value}>{children}</WeatherContext.Provider>
  )
}
