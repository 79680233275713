import { characters, DialogueConfig } from "."

export const dialogue_Beacon: DialogueConfig = {
  title: "A Beacon Found",
  messages: [
    {
      character: characters.arthur,
      text: "What’s this? The Righteous *Beacon of Barnstable* returns!",
    },
    {
      character: characters.peter,
      text: "I thought it'd been lost all these years. Where did you find it?",
    },
    {
      character: characters.arthur,
      text: "Why isn’t it obvious? It simply appeared when we needed it most. And need it we do.",
    },
    {
      character: characters.peter,
      text: `Worry not. The harvest may be as long as it is arduous, but we are not alone.`,
    },
    {
      character: characters.arthur,
      text: "Indeed. Our kin shall not stray. *The beacon will guide us*.",
    },
  ],
}
