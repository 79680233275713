import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_WelcomeHomePeter: DialogueConfig = {
  title: music[7].label,
  messages: [
    { character: characters.sirflambard, text: "Who goes there?" },
    {
      character: characters.arthur,
      text: "Good morrow *Sir Flambard*. Arthur Portal-Dolmen and Peter Talisman do humbly greet thee, most noble servant of the Lord.",
    },
    {
      character: characters.sirflambard,
      text: "Good morrow to you both. Now, here ye. Time is of the essence gentlemen as I am in search of two fugitives. Two filthy thieves to be hanged, drawn and quartered. Such-and-such *Ovaltine*... Hath they pass?",
    },
    {
      character: characters.arthur,
      text: "Regrettably, we can be of no help.",
    },
    {
      character: characters.sirflambard,
      text: "You\u2019ve eyed no criminals of this ilk?",
    },
    { character: characters.peter, text: "Our apologies." },
    {
      character: characters.action,
      text: "Sir Flambard de Quincy spots Peter\u2019s errant limbs",
    },
    {
      character: characters.sirflambard,
      text: "Say, what\u2019s wrong with you? Why do you move so? Do you jest in the presence of a knight?",
    },
    {
      character: characters.arthur,
      text: "Oh. Forgive us. We mean no disrespect. We ran recently into Wheatsheaf who supplied us with libations. Now we must kiss the hare\u2019s foot if we are to be home in time for supper.",
    },
    {
      character: characters.sirflambard,
      text: "Very well. You\u2019re free to go.",
    },
    { character: characters.arthur, text: "We thank ye." },
    { character: characters.peter, text: "Thank ye we do." },
    {
      character: characters.sirflambard,
      text: "And be steady on the ale, the crop is yet to be cut.",
    },
  ],
}
