import { injectGlobal } from "@emotion/css"

injectGlobal`
  @font-face {
    src: url(./assets/fonts/WT_Monarch_Nova.woff),
         url(./assets/fonts/WT_Monarch_Nova.woff2),
         url(./assets/fonts/WT_Monarch_Nova.eot);
    font-family: Monarch;
  }
`
