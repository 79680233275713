import { useRef } from "react"
import { AmbientLight, DirectionalLight, PointLight } from "three"
import { useThree, useFrame } from "@react-three/fiber"
import { useStore } from "../StoreProvider"

export const Sun = ({ isWaveform }: { isWaveform: boolean }) => {
  const rootStore = useStore()
  const { camera } = useThree()
  const weather = rootStore.weather
  const directionalLightRef = useRef<DirectionalLight>(null)
  const ambientLightRef = useRef<AmbientLight>(null)
  const nightLightRef = useRef<PointLight>(null)
  useFrame(() => {
    const directionalLight = directionalLightRef.current
    if (directionalLight) {
      directionalLight.color.copy(weather.sunColor.color)
      directionalLight.position.copy(weather.sunDirection.vector)
      if (!isWaveform) {
        directionalLight.position.applyAxisAngle(camera.up, camera.rotation.y)
      }
    }
    const ambientLight = ambientLightRef.current
    if (ambientLight) {
      ambientLight.color.lerpColors(
        weather.ambientColor.color,
        weather.lightningColor,
        weather.lightning
      )
    }
    const nightLight = nightLightRef.current
    if (nightLight) {
      nightLight.color.copy(weather.nightLightColor.color)
    }
  })
  return (
    <>
      <directionalLight ref={directionalLightRef} />
      <ambientLight ref={ambientLightRef} />
      {!isWaveform && (
        <pointLight
          ref={nightLightRef}
          position={[
            rootStore.world.map.center.x + 3,
            10,
            rootStore.world.map.center.y + 3,
          ]}
          distance={30}
          decay={0.8}
        />
      )}
    </>
  )
}
