import { TextureID } from "."
import { RootStore, StandingStoneStore } from "../store"

export type StandingStoneConfig = {
  isRevealed?: (
    rootStore: RootStore,
    standingStone: StandingStoneStore
  ) => boolean
}

export type StandingStoneID =
  | "standingStone1"
  | "standingStone2"
  | "standingStone3"
  | "standingStone4"
  | "standingStone5"
  | "standingStone6"
  | "standingStone7"
  | "standingStone8"
  | "standingStone9"
  | "standingStone10"

export const standingStones: Record<StandingStoneID, StandingStoneConfig> = {
  standingStone1: {},
  standingStone2: {},
  standingStone3: {},
  standingStone4: {},
  standingStone5: {},
  standingStone6: {},
  standingStone7: {},
  standingStone8: {},
  standingStone9: {},
  standingStone10: {},
}

export type ChalkFigureConfig = {
  textureID: TextureID
}

export type ChalkFigureID = "chalkFigure"
// | "celticTest"
// | "nokia2"
// | "RobotW2"
// | "ie"
// | "Dog"
// | "horseman"

export const chalkFigures: Record<ChalkFigureID, ChalkFigureConfig> = {
  chalkFigure: { textureID: "chalkFigure" },
  // celticTest: {
  //   textureID: "celticTest",
  // },
  // nokia2: {
  //   textureID: "nokia2",
  // },
  // RobotW2: {
  //   textureID: "RobotW2",
  // },
  // ie: {
  //   textureID: "ie",
  // },
  // Dog: {
  //   textureID: "Dog",
  // },
  // horseman: {
  //   textureID: "horseman",
  // },
}
