import { Color, Vector3 } from "three"
import { lerp } from "../utils/math"

export type WeatherConfig = {
  ambientColor: Color
  sunColor: Color
  sunDirection: Vector3
  nightLightColor: Color
  specularColor: Color
  windStrength: number
  rain: number
  lightningFrequency: number
  fogColor: Color
  fogHeight: number
  fogDensity: number
  skyColor1: Color
  skyColor2: Color
  skyColor3: Color
}
const night: WeatherConfig = {
  ambientColor: new Color(0x000000),
  sunColor: new Color(0x000000),
  sunDirection: new Vector3(1, 0.2, 1),
  nightLightColor: new Color(0xd1e9ff),
  specularColor: new Color(0x736950),
  windStrength: 0.3,
  rain: 0,
  lightningFrequency: 0,
  fogColor: new Color(0x9b95ba),
  fogDensity: 0.35,
  fogHeight: 4,
  skyColor1: new Color(0x887493),
  skyColor2: new Color(0x0d00ff),
  skyColor3: new Color(0x2e2e2e),
}
const dawn: WeatherConfig = {
  ambientColor: new Color(0x1d1721),
  sunColor: new Color(0x516675),
  sunDirection: new Vector3(1, 0.8, 1),
  nightLightColor: new Color(0x414141),
  specularColor: new Color(0x736950),
  windStrength: 0.8,
  rain: 0,
  lightningFrequency: 0,
  fogColor: new Color(0x9b95ba),
  fogDensity: 0.35,
  fogHeight: 2,
  skyColor1: new Color(0x2c3834),
  skyColor2: new Color(0x2019a7),
  skyColor3: new Color(0x000000),
}
const sunrise: WeatherConfig = {
  ambientColor: new Color(0x1d1721),
  sunColor: new Color(0x9b9e8e),
  sunDirection: new Vector3(1, 0.8, 1),
  nightLightColor: new Color(0x000000),
  specularColor: new Color(0xbe9441),
  windStrength: 0.8,
  rain: 0,
  lightningFrequency: 0,
  fogColor: new Color(0xd1f1ff),
  fogDensity: 0,
  fogHeight: 2,
  skyColor1: new Color(0xff9900),
  skyColor2: new Color(0x0014cb),
  skyColor3: new Color(0x1d1d1d),
}
const sunny: WeatherConfig = {
  ambientColor: new Color(0x3f3f3f),
  sunColor: new Color(0xffffff),
  sunDirection: new Vector3(1.25, 2, 1),
  nightLightColor: new Color(0x000000),
  specularColor: new Color(0xffffff),
  windStrength: 0.5,
  rain: 0,
  lightningFrequency: 0,
  fogColor: new Color(0x888888),
  fogDensity: 0,
  fogHeight: 2,
  skyColor1: new Color(0xa7a7a7),
  skyColor2: new Color(0xffffff),
  skyColor3: new Color(0x0049ff),
}
const drizzle: WeatherConfig = {
  ambientColor: new Color(0x212838),
  sunColor: new Color(0x9ea17f),
  sunDirection: new Vector3(1, 0.8, 1),
  nightLightColor: new Color(0x000000),
  specularColor: new Color(0xadadad),
  windStrength: 0.6,
  rain: 0.08,
  lightningFrequency: 0,
  fogColor: new Color(0x8aa0aa),
  fogDensity: 0.77,
  fogHeight: 2,
  skyColor1: new Color(0xcec655),
  skyColor2: new Color(0x0014cb),
  skyColor3: new Color(0x1d1d1d),
}
const morning = {
  ambientColor: new Color(0x3a4145),
  sunColor: new Color(0xfff0b6),
  sunDirection: new Vector3(0.5, 1, 0.5),
  nightLightColor: new Color(0x000000),
  specularColor: new Color(0xffffff),
  windStrength: 0.6,
  rain: 0,
  lightningFrequency: 0,
  fogColor: new Color(0xfdffd1),
  fogDensity: 0.07,
  fogHeight: 2,
  skyColor1: new Color(0xc7cb86),
  skyColor2: new Color(0x3a7ebe),
  skyColor3: new Color(0x26243b),
}
const choppy = {
  ambientColor: new Color(0x3a4145),
  sunColor: new Color(0xfff0b6),
  sunDirection: new Vector3(0.5, 1, 0.5),
  nightLightColor: new Color(0x000000),
  specularColor: new Color(0xffffff),
  windStrength: 0.8,
  rain: 0,
  lightningFrequency: 0,
  fogColor: new Color(0xfdffd1),
  fogDensity: 0.07,
  fogHeight: 2,
  skyColor1: new Color(0xc7cb86),
  skyColor2: new Color(0x3a7ebe),
  skyColor3: new Color(0x26243b),
}
const rain: WeatherConfig = {
  ambientColor: new Color(0x1c254e),
  sunColor: new Color(0xbababa),
  sunDirection: new Vector3(1.25, 2, 1),
  nightLightColor: new Color(0x000000),
  specularColor: new Color(0x009fff),
  windStrength: 0.5,
  rain: 0.6,
  lightningFrequency: 0,
  fogColor: new Color(0x888888),
  fogDensity: 0.5,
  fogHeight: 4,
  skyColor1: new Color(0x2f4158),
  skyColor2: new Color(0xbebebe),
  skyColor3: new Color(0x2d3c5c),
}
const storm: WeatherConfig = {
  ambientColor: new Color(0x1d1d1d),
  sunColor: new Color(0x809da7),
  sunDirection: new Vector3(1.25, 2, 1),
  nightLightColor: new Color(0x000000),
  specularColor: new Color(0x86ff92),
  windStrength: 1,
  rain: 0.6,
  lightningFrequency: 0.5,
  fogColor: new Color(0x494255),
  fogDensity: 0.3,
  fogHeight: 4,
  skyColor1: new Color(0x367870),
  skyColor2: new Color(0xaaa259),
  skyColor3: new Color(0x000000),
}
const interpolateWeather = (
  a: WeatherConfig,
  b: WeatherConfig,
  t: number
): WeatherConfig => ({
  ambientColor: new Color().lerpColors(a.ambientColor, b.ambientColor, t),
  sunColor: new Color().lerpColors(a.sunColor, b.sunColor, t),
  sunDirection: new Vector3().lerpVectors(a.sunDirection, b.sunDirection, t),
  nightLightColor: new Color().lerpColors(
    a.nightLightColor,
    b.nightLightColor,
    t
  ),
  specularColor: new Color().lerpColors(a.specularColor, b.specularColor, t),
  windStrength: lerp(a.windStrength, b.windStrength, t),
  rain: lerp(a.rain, b.rain, t),
  lightningFrequency: lerp(a.lightningFrequency, b.lightningFrequency, t),
  fogColor: new Color().lerpColors(a.fogColor, b.fogColor, t),
  fogHeight: lerp(a.fogHeight, b.fogHeight, t),
  fogDensity: lerp(a.fogDensity, b.fogDensity, t),
  skyColor1: new Color().lerpColors(a.skyColor1, b.skyColor1, t),
  skyColor2: new Color().lerpColors(a.skyColor2, b.skyColor2, t),
  skyColor3: new Color().lerpColors(a.skyColor3, b.skyColor3, t),
})

export const weather: WeatherConfig[] = [
  night, // prelude
  dawn, // life changing discovery
  sunrise, // we have to leave
  drizzle, // live from cley hill
  interpolateWeather(drizzle, morning, 0.5), // rest easy
  morning, // haha fucking quest
  interpolateWeather(morning, choppy, 0.5), // one for the road
  choppy, // welcome home
  interpolateWeather(sunny, choppy, 0.5), // absolute scene
  storm, // ley u down
  sunny, // equinox
]
