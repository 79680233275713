import { Vector2 } from "three"

const isInt = (n: number) => Math.floor(n) === n
const highestCommonFactor = (a: number, b: number) => {
  if (a === 0 && b === 0) return 1
  if (a === 0) return b
  if (b === 0) return a
  const min = Math.min(a, b)
  for (let n = min; n >= 2; n--) {
    if (isInt(a / n) && isInt(b / n)) return n
  }
  return 1
}
export const splitPathSegment = (from: Vector2, to: Vector2) => {
  const n = highestCommonFactor(
    Math.abs(from.x - to.x),
    Math.abs(from.y - to.y)
  )
  const path: Vector2[] = []
  const step = to.clone().sub(from).divideScalar(n)
  for (let i = 0; i <= n; i++) {
    path.push(new Vector2(from.x + step.x * i, from.y + step.y * i))
  }
  return path
}
export const pairs = <T>(arr: T[]) => {
  if (arr.length === 1) throw new Error("Can't pair 1 item")
  const pairs: [T, T][] = []
  for (let i = 0; i < arr.length - 1; i++) {
    pairs.push([arr[i], arr[i + 1]])
  }
  return pairs
}
export const splitPath = (path: Vector2[]) =>
  pairs(path).flatMap(([a, b]) => splitPathSegment(a, b))
