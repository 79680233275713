import { action, reaction, when } from "mobx"
import { clamp } from "../utils/math"
import { makeAutoObservable } from "mobx"
import { ObservableSpring } from "../utils/ObservableSpring"
import { RootStore } from "."

export class AudioStore {
  private readonly rootStore: RootStore
  private readonly element = new Audio()
  private readonly volumeSpring = new ObservableSpring({ fromValue: 1 })
  loadedTracks: boolean[]
  playing = false
  currentTrack: number
  currentTime = 0
  duration = 0
  loadingCurrentTrack = true
  preloading = false
  constructor(rootStore: RootStore, initialTrack: number) {
    this.rootStore = rootStore
    this.loadedTracks = rootStore.music.tracks.map(() => false)
    this.currentTrack = initialTrack
    this.element.src = this.rootStore.music.tracks[this.currentTrack].src

    makeAutoObservable(this)

    this.element.addEventListener(
      "play",
      action(() => {
        this.playing = true
      })
    )

    this.element.addEventListener(
      "pause",
      action(() => {
        this.playing = false
      })
    )

    this.element.addEventListener(
      "timeupdate",
      action(() => {
        this.currentTime = this.element.currentTime
      })
    )

    this.element.addEventListener(
      "loadedmetadata",
      action(() => {
        this.duration = this.element.duration
      })
    )

    this.element.addEventListener(
      "canplaythrough",
      action(() => {
        this.loadedTracks[this.currentTrack] = true
        this.loadingCurrentTrack = false
      })
    )

    this.element.addEventListener(
      "ended",
      action(() => {
        this.playing = false
        const nextTrack = this.rootStore.music.tracks.findIndex(
          (track, i) => i > this.currentTrack && track.unlocked
        )
        if (nextTrack > -1) this.playTrack(nextTrack)
      })
    )

    reaction(
      () =>
        !this.loadingCurrentTrack &&
        !this.preloading &&
        !this.loadedTracks.every(loaded => loaded),
      shouldPreload => {
        if (shouldPreload) this.preloadNextTrack()
      }
    )

    reaction(
      () => this.volumeSpring.value,
      volume => (this.element.volume = clamp(volume, 0, 1))
    )
  }

  private nextTrack: number | null = null
  private fadingOut = false;
  *playTrack(index: number) {
    this.nextTrack = index
    if (this.fadingOut) return
    if (this.playing) {
      this.fadingOut = true
      this.volumeSpring.to(0)
      yield when(() => this.volumeSpring.isAtRest)
      this.volumeSpring.updateConfig({ fromValue: 1, toValue: 1 })
      this.element.volume = 1
      this.fadingOut = false
    }
    this.currentTrack = this.nextTrack
    this.element.src = this.rootStore.music.tracks[this.nextTrack].src
    this.currentTime = 0
    this.nextTrack = null
    this.loadingCurrentTrack = true
    return this.play()
  }

  *play() {
    if (!this.element.paused) return true
    try {
      yield this.element.play()
      this.playing = true
      return true
    } catch (error) {
      this.playing = false
      console.warn("audio couldn't play", error)
      return false
    }
  }

  pause() {
    if (this.element.paused) return
    this.element.pause()
  }

  toggle() {
    if (this.playing) {
      return Promise.resolve(this.pause())
    } else {
      return this.play()
    }
  }

  seek(time: number) {
    this.element.currentTime = time
  }

  *preloadNextTrack() {
    const index = this.loadedTracks.findIndex(loaded => !loaded)
    if (index === -1) return
    this.preloading = true
    yield new Promise<void>(resolve => {
      const audio = new Audio()
      audio.addEventListener("canplaythrough", () => {
        resolve()
      })
      audio.preload = "auto"
      audio.src = this.rootStore.music.tracks[index].src
    })
    this.loadedTracks[index] = true
    this.preloading = false
  }

  dispose() {
    this.element.src = ""
  }
}
