import { IWorldObject } from "."
import { Vector2 } from "three"
import { makeAutoObservable } from "mobx"
import { RootStore } from "./index"
import { StandingStoneConfig, StandingStoneID } from "../config/world"
import { rectangleEdge } from "../utils/tiles"

export class StandingStoneStore implements IWorldObject {
  id: StandingStoneID
  position: Vector2
  size = new Vector2(3, 3)
  rootStore: RootStore
  track: number | null = null
  config: StandingStoneConfig
  constructor(
    rootStore: RootStore,
    id: StandingStoneID,
    position: Vector2,
    config: StandingStoneConfig
  ) {
    this.id = id
    this.rootStore = rootStore
    this.position = position
    this.config = config
    makeAutoObservable(this)
  }
  claim() {
    if (!this.isClaimed) {
      this.track = this.rootStore.world.numClaimedStandingStones + 1
    }
    return this.track!
  }
  get isClaimed() {
    return this.track !== null
  }
  get neighbours() {
    return rectangleEdge(this.size.clone().addScalar(2), false).map(coord =>
      coord.add(this.position).subScalar(1)
    )
  }
  get isAccessible() {
    return this.neighbours.some(
      coord =>
        this.rootStore.world.map.isInBounds(coord) &&
        !this.rootStore.world.map.isSolid(coord)
    )
  }
  get isRevealed() {
    if (!this.config.isRevealed) return true
    return this.config.isRevealed(this.rootStore, this)
  }
}
