import { Spring, SpringConfig } from "wobble"
import { action, makeObservable, observable } from "mobx"

export class ObservableSpring {
  private spring: Spring
  value!: number
  velocity!: number
  isAtRest!: boolean
  isAnimating!: boolean
  constructor(config: Partial<SpringConfig> = {}) {
    this.spring = new Spring(config)
    makeObservable(this, {
      value: observable,
      velocity: observable,
      isAtRest: observable,
      isAnimating: observable,
      sync: action,
    })
    this.spring.onStart(() => this.sync())
    this.spring.onStop(() => this.sync())
    this.spring.onUpdate(() => this.sync())
    this.sync()
  }
  sync() {
    this.value = this.spring.currentValue
    this.velocity = this.spring.currentVelocity
    this.isAtRest = this.spring.isAtRest
    this.isAnimating = this.spring.isAnimating
  }
  start() {
    this.spring.start()
  }
  stop() {
    this.spring.stop()
  }
  updateConfig(config: Partial<SpringConfig>) {
    this.spring.updateConfig(config)
  }
  to(toValue: number) {
    this.updateConfig({ toValue })
    this.spring.start()
  }
  static config = {
    default: { stiffness: 170, damping: 26 },
    gentle: { stiffness: 120, damping: 14 },
    wobbly: { stiffness: 180, damping: 12 },
    stiff: { stiffness: 210, damping: 20 },
    slow: { stiffness: 280, damping: 60 },
    molasses: { stiffness: 280, damping: 120 },
  }
}
