import { useControls } from "leva"
import { memo } from "react"
import { Color, Vector3 } from "three"
import { useStore } from "../StoreProvider"
import { Sun } from "./Sun"
import { Rain } from "./Rain/Rain"

const useWeatherControls = () => {
  const rootStore = useStore()
  const { weather } = rootStore
  useControls("Weather", () => ({
    ambientColor: {
      value: "#" + weather.ambientColor.color.getHexString(),
      onChange: color =>
        weather.ambientColor
          .updateConfig({ toValue: new Color(color) })
          .start(),
    },
    sunColor: {
      value: "#" + weather.sunColor.color.getHexString(),
      onChange: color =>
        weather.sunColor.updateConfig({ toValue: new Color(color) }).start(),
    },
    sunDirection: {
      value: weather.sunDirection.vector.toArray(),
      step: 0.01,
      onChange: vector =>
        weather.sunDirection
          .updateConfig({ toValue: new Vector3(...vector) })
          .start(),
    },
    nightLightColor: {
      value: "#" + weather.nightLightColor.color.getHexString(),
      onChange: color =>
        weather.nightLightColor
          .updateConfig({
            toValue: new Color(color),
          })
          .start(),
    },
    specularColor: {
      value: "#" + weather.specularColor.color.getHexString(),
      onChange: color =>
        weather.specularColor
          .updateConfig({
            toValue: new Color(color),
          })
          .start(),
    },
    windStrength: {
      value: weather.windStrength.currentValue,
      min: 0,
      max: 1,
      onChange: toValue =>
        weather.windStrength.updateConfig({ toValue }).start(),
    },
    rain: {
      value: weather.rain.currentValue,
      min: 0,
      max: 1,
      onChange: toValue => weather.rain.updateConfig({ toValue }).start(),
    },
    lightningFrequency: {
      value: weather.lightningFrequency.currentValue,
      min: 0,
      max: 1,
      onChange: toValue =>
        weather.lightningFrequency.updateConfig({ toValue }).start(),
    },
    fogColor: {
      value: "#" + weather.fogColor.color.getHexString(),
      onChange: color =>
        weather.fogColor
          .updateConfig({
            toValue: new Color(color),
          })
          .start(),
    },
    fogHeight: {
      value: weather.fogHeight.currentValue,
      min: 0,
      max: 10,
      onChange: toValue => weather.fogHeight.updateConfig({ toValue }).start(),
    },
    fogDensity: {
      value: weather.fogDensity.currentValue,
      min: 0,
      max: 1,
      onChange: toValue => weather.fogDensity.updateConfig({ toValue }).start(),
    },
    skyColor1: {
      value: "#" + weather.skyColor1.color.getHexString(),
      onChange: color =>
        weather.skyColor1
          .updateConfig({
            toValue: new Color(color),
          })
          .start(),
    },
    skyColor2: {
      value: "#" + weather.skyColor2.color.getHexString(),
      onChange: color =>
        weather.skyColor2
          .updateConfig({
            toValue: new Color(color),
          })
          .start(),
    },
    skyColor3: {
      value: "#" + weather.skyColor3.color.getHexString(),
      onChange: color =>
        weather.skyColor3
          .updateConfig({
            toValue: new Color(color),
          })
          .start(),
    },
  }))
}

export const Weather = memo(
  ({ isWaveform = false }: { isWaveform?: boolean }) => {
    useWeatherControls()
    return (
      <>
        <Sun isWaveform={isWaveform} />
        <Rain />
      </>
    )
  }
)
