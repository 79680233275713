export const mapObject = <KeyType extends string, InValueType, OutValueType>(
  obj: Record<KeyType, InValueType>,
  fn: (value: InValueType, key: KeyType) => OutValueType
) => {
  const result: Partial<Record<KeyType, OutValueType>> = {}
  const keys = Object.keys(obj) as KeyType[]
  keys.forEach(key => (result[key] = fn(obj[key], key)))
  return result as Record<KeyType, OutValueType>
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export const entries = <T extends {}>(obj: T): Entries<T> =>
  Object.entries(obj) as any
