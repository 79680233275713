import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_DanceOfTheEquinox: DialogueConfig = {
  title: music[10].label,
  messages: [
    {
      character: characters.peter,
      text: "We\u2019ve done it Arthur. We\u2019ve found them all!",
    },
    {
      character: characters.arthur,
      text: "I feel a resolution! But what\u2019s this? Wait\u2026 *I hear it too*.",
    },
    { character: characters.peter, text: "You do?" },
    {
      character: characters.arthur,
      text: "Those spectral sounds, urging me to move.",
    },
    {
      character: characters.peter,
      text: "I knew you would, when the time had come. Then surely you should shake a leg!",
    },
    {
      character: characters.arthur,
      text: "Of course. I must. The rapture\u2019s taking hold of me. Here I come to rave with thee.",
    },
    {
      character: characters.action,
      text: "A frenzied celebration erupts. In wild delirium, the people move, writhing and twisting to the sounds from beyond",
    },
  ],
}
