import {
  RepeatWrapping,
  MirroredRepeatWrapping,
  NearestFilter,
  Texture,
  TextureLoader,
  Mesh,
  MeshStandardMaterial,
  LineSegments,
  BufferGeometry,
  Vector3,
  BufferAttribute,
} from "three"
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { makeObservable, observable, runInAction } from "mobx"
import {
  textures as textureURLs,
  models as modelURLs,
  mapBitmap,
  mapSVG,
  music,
} from "../config"
import { MeshWeatherMaterial } from "../components/Weather"

interface ILoader<T> {
  loadAsync(url: string): Promise<T>
}
const textureLoader = new TextureLoader() as ILoader<Texture>
const gltfLoader = new GLTFLoader() as ILoader<GLTF>

const loadImage = (url: string) =>
  new Promise<HTMLImageElement>(resolve => {
    const image = new Image()
    image.onload = () => resolve(image)
    image.src = url
  })

const loadObject = async <K extends string, V>(
  urls: Record<K, string>,
  loader: ILoader<V>
) => {
  type Result = Record<K, V>
  const result: Partial<Result> = {}
  const keys = Object.keys(urls) as K[]
  await Promise.all(
    keys.map(async key => {
      const url = urls[key]
      const value = await loader.loadAsync(url)
      result[key] = value
    })
  )
  return result as Result
}

const addLengthToLineSegments = (geometry: BufferGeometry) => {
  const position = geometry.getAttribute("position")
  let totalLength = 0
  const length = new BufferAttribute(new Float32Array(position.count), 1)
  const v1 = new Vector3()
  const v2 = new Vector3()
  for (let i = 0; i < position.count; i += 2) {
    v1.fromBufferAttribute(position, i)
    v2.fromBufferAttribute(position, i + 1)
    const lineSegmentLength = v1.distanceTo(v2)
    length.setX(i, totalLength)
    length.setX(i + 1, totalLength + lineSegmentLength)
    totalLength += lineSegmentLength
  }
  geometry.setAttribute("length", length)
}

export class Assets {
  loaded = false
  constructor() {
    makeObservable(this, { loaded: observable })
  }

  private _textures?: Record<keyof typeof textureURLs, Texture>
  get textures() {
    if (this._textures === undefined) throw new Error("Textures not loaded")
    return this._textures
  }

  private _models?: Record<keyof typeof modelURLs, GLTF>
  get models() {
    if (this._models === undefined) throw new Error("Models not loaded")
    return this._models
  }

  private _map?: {
    tiles: HTMLImageElement
    objects: string
  }
  get map() {
    if (this._map === undefined) throw new Error("Map not loaded")
    return this._map
  }

  async loadAll() {
    await Promise.all([this.loadTextures(), this.loadModels(), this.loadMap()])
    runInAction(() => (this.loaded = true))
  }
  private async loadTextures() {
    this._textures = await loadObject(textureURLs, textureLoader)
    ;[this.textures.peasant, this.textures.donkey, this.textures.rain].forEach(
      texture => (texture.magFilter = texture.minFilter = NearestFilter)
    )

    this.textures.peasant.repeat.x = 1 / 5
    this.textures.peasant.repeat.y = 1 / 11
    this.textures.peasant.wrapS = this.textures.peasant.wrapT =
      MirroredRepeatWrapping

    this.textures.rain.wrapS = this.textures.rain.wrapT = RepeatWrapping
  }
  private async loadModels() {
    this._models = await loadObject(modelURLs, gltfLoader)
    Object.values(this._models).forEach(gltf => {
      gltf.scene.traverse(object => {
        if (object instanceof Mesh) {
          if (object.material instanceof MeshStandardMaterial) {
            const standardMaterial = object.material
            object.material = new MeshWeatherMaterial({})
            MeshStandardMaterial.prototype.copy.call(
              object.material,
              standardMaterial
            )
          }
          if (object.material.map) {
            object.material.map.magFilter = object.material.map.minFilter =
              NearestFilter
          }
        } else if (object instanceof LineSegments) {
          object.geometry = object.geometry.toNonIndexed()
          addLengthToLineSegments(object.geometry)
        }
      })
    })
  }
  private async loadMapObjects() {
    const response = await fetch(mapSVG)
    if (!response.ok) throw new Error("SVG not found")
    return await response.text()
  }
  private async loadMap() {
    const [tiles, objects] = await Promise.all([
      loadImage(mapBitmap),
      this.loadMapObjects(),
    ])
    this._map = {
      tiles,
      objects,
    }
  }
}
