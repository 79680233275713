import { SpringConfig } from "wobble"
import { ObservableSpring } from "./ObservableSpring"
import { autorun, computed, makeObservable, observable, when } from "mobx"

type TransitionSpringConfig = Partial<
  Omit<SpringConfig, "fromValue" | "toValue" | "initialVelocity">
>

type TransitionConfig<Value, Key> = {
  get: () => Value | Value[]
  key?: (value: Value) => Key
  initialEnter?: boolean
  springConfig?: TransitionSpringConfig
}

type TransitionItemConfig<Item, Key> = {
  transition: ObservableTransition<Item, Key>
  value: Item
  key: Key
  springConfig: Partial<SpringConfig>
  initialEnter: boolean
}

export class TransitionItem<Value, Key> {
  transition: ObservableTransition<Value, Key>
  value: Value
  key: Key
  private tSpring: ObservableSpring
  get t() {
    return this.tSpring.value
  }
  private enterSpring: ObservableSpring
  get enter() {
    return this.enterSpring.value
  }
  private leaveSpring: ObservableSpring
  get leave() {
    return this.leaveSpring.value
  }
  constructor({
    transition,
    value,
    key,
    springConfig,
    initialEnter,
  }: TransitionItemConfig<Value, Key>) {
    this.transition = transition
    this.value = value
    this.key = key
    this.tSpring = new ObservableSpring({
      ...springConfig,
      fromValue: initialEnter ? 0 : 1,
      toValue: 1,
    })
    this.enterSpring = new ObservableSpring({
      ...springConfig,
      fromValue: initialEnter ? 0 : 1,
      toValue: 1,
    })
    this.leaveSpring = new ObservableSpring({
      ...springConfig,
      fromValue: 0,
      toValue: 0,
    })
    when(
      () => this.leaveSpring.value === 1,
      () => this.transition.map.delete(this.key)
    )
  }
  transitionIn() {
    this.tSpring.to(1)
    this.enterSpring.to(1)
    this.leaveSpring.to(0)
  }
  transitionOut() {
    this.tSpring.to(0)
    this.enterSpring.to(1)
    this.leaveSpring.to(1)
  }
}

export class ObservableTransition<Value, Key> {
  private springConfig: TransitionSpringConfig
  private key: (value: Value) => Key
  private initialEnter: boolean
  map: Map<Key, TransitionItem<Value, Key>> = new Map()
  constructor({
    get,
    key = v => v as unknown as Key,
    initialEnter = false,
    springConfig = {},
  }: TransitionConfig<Value, Key>) {
    this.key = key
    this.springConfig = springConfig
    this.initialEnter = initialEnter
    makeObservable(this, { map: observable, items: computed })
    autorun(() => this.update(get()))
  }
  get items() {
    return [...this.map.values()]
  }
  private isInitialTransition = true
  private update(values: Value | Value[]) {
    if (!Array.isArray(values)) values = [values]
    const nextValues = new Map(values.map(value => [this.key(value), value]))
    for (const key of nextValues.keys()) {
      if (!this.map.has(key)) {
        this.map.set(key, this.createItem(key, nextValues.get(key)!))
      }
      this.map.get(key)!.transitionIn()
    }
    for (const key of this.map.keys()) {
      if (!nextValues.has(key)) this.map.get(key)!.transitionOut()
    }
    this.isInitialTransition = false
  }
  private createItem(key: Key, value: Value) {
    return new TransitionItem<Value, Key>({
      transition: this,
      value,
      key,
      initialEnter: !this.isInitialTransition || this.initialEnter,
      springConfig: this.springConfig,
    })
  }
}
