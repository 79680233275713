import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_ALifeChangingDiscovery: DialogueConfig = {
  title: music[1].label,
  messages: [
    { character: characters.peter, text: "Stars above, there it is." },
    { character: characters.arthur, text: "The murmurs were true!" },
    {
      character: characters.peter,
      text: "I didn't rightly believe you until I touched upon it with my own hand.",
    },
    {
      character: characters.arthur,
      text: "Fair is fair. I scarcely believe my own words of late.",
    },
    { character: characters.peter, text: "Wait... Listen..." },
    {
      character: characters.peter,
      text: "A *song*. Do you hear a melody as I do?",
    },
    {
      character: characters.arthur,
      text: "It's odd. I hear nothing, though I feel it somehow.",
    },
    {
      character: characters.peter,
      text: "What a curious specimen of stone stands before us.",
    },
    {
      character: characters.action,
      text: "Peter begins to sway with corn as the sound takes him.",
    },
    {
      character: characters.arthur,
      text: "Could it be that there are *others* like this, waiting for us to greet them?",
    },
    {
      character: characters.peter,
      text: "I sense that our task has only just begun, Arthur. I s'pose I ought to *clear us a path*.",
    },
  ],
}
