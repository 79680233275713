import { MathUtils, Vector2 } from "three"
import { IWorldObject } from "."
import { rectangleEdge } from "../utils/tiles"
import { RootStore } from "./index"

export class GranaryStore implements IWorldObject {
  id = MathUtils.generateUUID()
  rootStore: RootStore
  position = new Vector2()
  size = new Vector2(3, 3)
  constructor(rootStore: RootStore, position: Vector2) {
    this.rootStore = rootStore
    this.position = position
  }
  getDropOffPoints() {
    const edgeSize = this.size.clone().addScalar(2)
    return rectangleEdge(edgeSize).map(point =>
      point.add(this.position).subScalar(1)
    )
  }
}
