import { music } from "../music"
import { characters } from "./characters"
import { DialogueConfig } from "./dialogue"

export const dialogue_ADifficultFog: DialogueConfig = {
  title: music[0].label,
  messages: [
    { character: characters.arthur, text: "*Peter Talisman*, is that you?" },
    { character: characters.peter, text: "Greetings, Arthur." },
    {
      character: characters.arthur,
      text: "Back to the *harvest* again, is it? You can't leave well alone, can you?",
    },
    {
      character: characters.peter,
      text: "Time is a wheel as well as an arrow, Arthur. Come rain, come shine, it's my duty to gather the grain and mill it into a cereal of grace and absolution.",
    },
    {
      character: characters.arthur,
      text: "Right you are. A terrible tyranny is our hunger.",
    },
    {
      character: characters.peter,
      text: "I should just as well ask you about your business here?",
    },
    {
      character: characters.arthur,
      text: "Well. I heard tell of an ancient structure out this way. An old, old *monument* hidden betwixt the crop.",
    },
    {
      character: characters.peter,
      text: "And you can't find it through all this cursed corn? Well then old friend, let's see if I can't aid you in your search. We'd best be on with the cutting then.",
    },
  ],
}
