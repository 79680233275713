import { RootStore, IWorldObject } from "."
import { Vector2 } from "three"
import { makeAutoObservable } from "mobx"
import { LoreID, LoreObjectConfig } from "../config"
import { rectangleEdge } from "../utils/tiles"

export class LoreObjectStore implements IWorldObject {
  id: LoreID
  rootStore: RootStore
  position: Vector2
  size = new Vector2(1, 1)
  config: LoreObjectConfig
  isRead = false
  constructor(
    rootStore: RootStore,
    id: LoreID,
    position: Vector2,
    config: LoreObjectConfig
  ) {
    this.rootStore = rootStore
    this.id = id
    this.position = position
    this.config = config
    makeAutoObservable(this)
  }
  setRead() {
    this.isRead = true
  }
  get neighbours() {
    return rectangleEdge(this.size.clone().addScalar(5), false).map(coord =>
      coord.add(this.position).subScalar(1)
    )
  }
  get isFound() {
    return !this.rootStore.world.map.isSolid(this.position)
  }
  get isVisible() {
    return (
      this.isFound ||
      this.neighbours.some(
        coord =>
          this.rootStore.world.map.isInBounds(coord) &&
          !this.rootStore.world.map.isSolid(coord)
      )
    )
  }
}
