import { Vector2 } from "three"
import { RootStore, TileType } from "."
import { makeAutoObservable } from "mobx"

export class PlayerStore {
  rootStore: RootStore
  corn = 0
  heldCorn = 0
  maxHeldCorn = 20
  yield = 0
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  get isHeldResourcesFull() {
    return this.heldCorn >= this.maxHeldCorn
  }
  canHarvest(coord: Vector2) {
    const map = this.rootStore.world.map
    return map.isAccessible(coord) && map.is(coord, TileType.Corn)
  }
  pickUpCorn(n: number) {
    this.heldCorn += n
  }
  addCorn(n: number) {
    this.corn += n
    this.yield += n
  }
  depositHeldCorn() {
    this.addCorn(this.heldCorn)
    this.heldCorn = 0
  }
}
