import { Box, Stack, HStack } from "@chakra-ui/layout"
import { Text, HTMLChakraProps } from "@chakra-ui/react"
import { pageMargin } from "../../styles/theme"
import { credits } from "../../config"
import { LatticeBorder, loopBorder } from "../UI/Borders"

const Credits = (props: HTMLChakraProps<"div">) => (
  <Box
    layerStyle="shadow"
    textAlign="center"
    padding={pageMargin}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    sx={loopBorder}
    {...props}
  >
    {credits.game.map(({ name, role, url }, i) => {
      const contents = (
        <>
          <Text textTransform="uppercase" color="yellow">
            {name}
          </Text>
          <Text paddingBottom={4}>{role}</Text>
        </>
      )
      if (url) {
        return (
          <Box
            as="a"
            href={url}
            target="_blank"
            key={i}
            _hover={{ color: "yellow" }}
          >
            {contents}
          </Box>
        )
      } else {
        return <Box key={i}>{contents}</Box>
      }
    })}
  </Box>
)

const Album = (props: HTMLChakraProps<"div">) => (
  <Box
    textAlign="center"
    layerStyle="shadow"
    padding={pageMargin}
    as="a"
    display="block"
    href="https://petertalisman.bandcamp.com/album/lord-of-the-harvest"
    target="_blank"
    color="yellow"
    _hover={{ color: "white" }}
    sx={loopBorder}
    {...props}
  >
    <Stack
      spacing={pageMargin}
      paddingX={pageMargin}
      height="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box flexGrow={1} flexBasis="100%">
        <Box
          width="14em"
          as="img"
          src="assets/logo/logo2.png"
          style={{
            imageRendering: "pixelated",
          }}
        />
      </Box>
      <Box as="img" width="18em" src="assets/album.png"></Box>
      <Box
        flexGrow={1}
        flexBasis="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Text>Acquire The Album</Text>
      </Box>
    </Stack>
  </Box>
)

const Title = ({
  loaded,
  start,
  ...props
}: HTMLChakraProps<"div"> & { loaded: boolean; start: () => void }) => (
  <LatticeBorder
    padding={pageMargin}
    textAlign="center"
    display="flex"
    justifyContent="center"
    flexDirection="column"
    layerStyle="shadow"
    {...props}
  >
    <Text>Peter Talisman</Text>
    <Text>Lord of the Harvest</Text>
    <Box>
      {loaded ? (
        <Text
          color="yellow"
          cursor="pointer"
          _hover={{ color: "white" }}
          onClick={start}
        >
          Begin
        </Text>
      ) : (
        <Text>Loading...</Text>
      )}
    </Box>
  </LatticeBorder>
)

const SmallPrint = (props: HTMLChakraProps<"div">) => (
  <Box
    textAlign="center"
    justifyContent="center"
    alignItems="center"
    fontSize="0.8em"
    width="30em"
    {...props}
  >
    <Text>Runtime: ~40 minutes</Text>
    <Text>
      A reasonably fast computer, Chrome browser
      <br />
      and headphones are reccommended.
    </Text>
  </Box>
)

type TitleScreenProps = {
  loaded: boolean
  start: () => void
} & HTMLChakraProps<"div">

export const TitleScreen = ({ loaded, start, ...props }: TitleScreenProps) => (
  <HStack
    padding={pageMargin}
    display="flex"
    alignItems="stretch"
    justifyContent="center"
    zIndex={10}
    {...props}
  >
    <Credits width="20em" />
    <Box
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Title loaded={loaded} start={start} width="26em" height="16em" />
      <SmallPrint position="absolute" bottom={0} />
    </Box>
    <Album width="20em" />
  </HStack>
)
