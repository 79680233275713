export const mapBitmap = "assets/map3.png"
export const mapSVG = "assets/map.svg"

export const textures = {
  peasant: "assets/textures/peasant-white.png",
  donkey: "assets/textures/donkey.png",
  title0: "assets/textures/title-0.png",
  title1: "assets/textures/title-1.png",
  title2: "assets/textures/title-2.png",
  title3: "assets/textures/title-3.png",
  title4: "assets/textures/title-4.png",
  title5: "assets/textures/title-5.png",
  title6: "assets/textures/title-6.png",
  title7: "assets/textures/title-7.png",
  title8: "assets/textures/title-8.png",
  title9: "assets/textures/title-9.png",
  title10: "assets/textures/title-10.png",
  title11: "assets/textures/title-11.png",
  chalkFigure: "assets/textures/chalkFigure.png",
  rain: "assets/textures/rain.png",
}

export type TextureID = keyof typeof textures

export const models = {
  stones: "assets/models/stones.glb",
  granary: "assets/models/granary.glb",
}
