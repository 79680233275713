import { PeasantStore, RootStore, TileType, DonkeyStore, GamePhase } from "."
import { makeAutoObservable, reaction } from "mobx"
import { Vector2 } from "three"
import { findMax, findMin } from "../utils/math"
import { getCircleEdge } from "../utils/tiles"

const sample = <T>(array: T[]) =>
  array[Math.floor(Math.random() * array.length)]

const shuffle = <T>(array: T[]) => {
  let currentIndex = array.length,
    randomIndex
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }
  return array
}

export class WorkerManagerStore {
  rootStore: RootStore
  peasants: PeasantStore[] = []
  donkeys: DonkeyStore[] = []
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  init() {
    this.disposers.push(
      reaction(
        () => this.rootStore.gamePhase === GamePhase.Dance,
        dance => {
          if (dance) this.danceLocations = this.getDanceLocations()
        },
        { fireImmediately: true }
      )
    )
  }
  step(dT: number) {
    for (const peasant of this.peasants) peasant.step(dT)
    for (const donkey of this.donkeys) donkey.step(dT)
  }
  createPeasant() {
    const position = sample(this.rootStore.world.granary.getDropOffPoints())
    this.peasants.push(new PeasantStore(this.rootStore, position))
  }
  createDonkey() {
    const position = sample(this.rootStore.world.granary.getDropOffPoints())
    this.donkeys.push(new DonkeyStore(this.rootStore, position))
  }
  canHarvest(coord: Vector2 | number) {
    const map = this.rootStore.world.map
    return map.isAccessible(coord) && map.is(coord, TileType.Corn)
  }
  get harvestableTiles() {
    const map = this.rootStore.world.map
    return [...map.accessibleTiles.values()].filter(index =>
      map.is(index, TileType.Corn)
    )
  }
  private claimedHarvestTargets = new Set<number>()
  claimHarvestTarget(target: Vector2) {
    this.claimedHarvestTargets.add(this.rootStore.world.map.toIndex(target))
  }
  unclaimHarvestTarget(target: Vector2) {
    this.claimedHarvestTargets.delete(this.rootStore.world.map.toIndex(target))
  }
  isHarvestTargetClaimed(target: Vector2) {
    return this.claimedHarvestTargets.has(
      this.rootStore.world.map.toIndex(target)
    )
  }
  get availableHarvestTargets() {
    return this.harvestableTiles.filter(
      index => !this.claimedHarvestTargets.has(index)
    )
  }
  getHarvestTargetForPeasant(peasant: PeasantStore) {
    const map = this.rootStore.world.map
    const coord = new Vector2()
    const { position: beaconPosition, isInCorn: beaconIsInCorn } =
      this.rootStore.world.beacon
    const tileIndex = findMin(this.availableHarvestTargets, tileIndex => {
      map.toCoord(tileIndex, coord)
      let distance = 0
      const distanceToPeasant = coord.distanceToSquared(peasant.position)
      distance += distanceToPeasant
      if (peasant.corn === 0 || beaconIsInCorn) {
        const distanceToBeacon = coord.distanceToSquared(beaconPosition)
        distance += distanceToBeacon * 5
      }
      return distance
    })
    if (!tileIndex) return null
    const target = map.toCoord(tileIndex, coord)
    const location = this.getHarvestLocation(coord)
    return { target, location }
  }
  getHarvestLocation(harvestTarget: Vector2) {
    const map = this.rootStore.world.map
    const granaryPosition = this.rootStore.world.granary.position
    const neighbours = map
      .getNeighbours(harvestTarget, false)
      .filter(coord => !map.isSolid(coord))
    const best = findMin(neighbours, coord =>
      coord.distanceToSquared(granaryPosition)
    )
    if (!best) throw new Error("Can't get harvest location")
    return best
  }

  get priorityHarvestTargets() {
    const beaconPosition = this.rootStore.world.beacon.position
    const map = this.rootStore.world.map
    const v = new Vector2()
    const sortedTargets = [...this.harvestableTiles]
      .map(index => ({
        index,
        score: 1 / map.toCoord(index, v).distanceToSquared(beaconPosition),
      }))
      .sort((a, b) => b.score - a.score)
    if (!sortedTargets.length) return []
    const maxScore = sortedTargets[0].score
    const minScore = 1 / (Math.sqrt(1 / maxScore) + 3) ** 2
    const minTargets = this.peasants.length
    const priorityTargets: { index: number; score: number }[] = []
    for (const target of sortedTargets) {
      if (target.score > minScore || priorityTargets.length < minTargets) {
        priorityTargets.push(target)
      } else {
        break
      }
    }
    return priorityTargets
  }

  danceLocations = new Map<PeasantStore, Vector2>()
  getDanceLocations() {
    const map = this.rootStore.world.map
    const centers = [
      this.rootStore.world.granary,
      ...this.rootStore.world.standingStones,
    ].map(object =>
      object.size.clone().multiplyScalar(0.5).floor().add(object.position)
    )
    const peasantsPerCircle =
      Math.ceil(this.peasants.length / centers.length) * 1.5
    const circles = centers
      .map(center => {
        const circles: Vector2[][] = []
        for (let r = 4; r <= 10; r++) {
          const circlePoints = getCircleEdge(r)
            .map(point => point.add(center))
            .filter(point => !map.isSolid(point))
          if (circlePoints.length >= peasantsPerCircle) return circlePoints
          if (circlePoints.length > 0) circles.push(circlePoints)
        }
        if (!circles.length) return []
        return findMax(circles, points => points.length)!
      })
      .filter(circlePoints => circlePoints.length > 0)
    if (!circles.length) return new Map<PeasantStore, Vector2>()
    circles.forEach(shuffle)
    const points: Vector2[] = []
    const maxLength = Math.max(
      ...circles.map(circlePoints => circlePoints.length)
    )
    while (points.length < this.peasants.length) {
      for (let i = 0; i < maxLength; i++) {
        for (const circlePoints of circles) {
          if (i < circlePoints.length) points.push(circlePoints[i])
        }
      }
    }
    const peasants = new Set(this.peasants)
    const locations = new Map<PeasantStore, Vector2>()
    points.forEach(point => {
      const peasant = findMin(peasants, peasant =>
        peasant.position.distanceToSquared(point)
      )!
      peasants.delete(peasant)
      locations.set(peasant, point)
    })
    return locations
  }

  disposers: (() => void)[] = []
  dispose() {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }
}
