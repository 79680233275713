import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_WeHaveToLeaveThisTown: DialogueConfig = {
  title: music[2].label,
  messages: [
    {
      character: characters.arthur,
      text: "Hail, strangers. Might I bother you a moment?",
    },
    {
      character: characters.jessica,
      text: "Of course. Though we can't linger long.",
    },
    {
      character: characters.arthur,
      text: "Have you on your travels encountered any standing stones?",
    },
    {
      character: characters.jessica,
      text: "There are many menhirs about here. Over there. And over there.",
    },
    { character: characters.peter, text: "Our thanks, truly." },
    {
      character: characters.arthur,
      text: "And to where is it that you two are destined?",
    },
    {
      character: characters.john,
      text: "We are done with this tawdry Estate. We are in search of a new kingdom to preside over.",
    },
    { character: characters.peter, text: "Bold words." },
    {
      character: characters.action,
      text: "The chicken that John Ovaltine clutches gives a strained squawk as he raises it above his head.",
    },
    { character: characters.arthur, text: "What is going on here?" },
    { character: characters.jessica, text: "I apologise for my husband." },
    {
      character: characters.arthur,
      text: "You look like no King, nor Lord, that I would recognise.",
    },
    {
      character: characters.jessica,
      text: "Please pay him no mind. We are but lowly peasants in search of a new home.",
    },
    {
      character: characters.arthur,
      text: "And why do you seek a new home so urgently?",
    },
    {
      character: characters.jessica,
      text: "My husband's delusions have made it a necessity. You see he is under the impression that he is himself the Lord. Many misdeeds he has undertaken of late.",
    },
    { character: characters.peter, text: "Pray tell\u2026" },
    { character: characters.arthur, text: "What has transpired?" },
    {
      character: characters.jessica,
      text: "Well, our troubles began the night before last. John returned to our humble hovel wearing an outfit of the most delicate fabrics, fine linens as white as the cliffs of Dover. He wouldn't account for their provenance and begged me to inquire no further.",
    },
    {
      character: characters.jessica,
      text: "Then, this evening prior, during the Lord's harvest feast, John entered the Manor House and without warning began to help himself to the stock of the Lord's Pantry. He gorged on fresh bread, rare meats, and exotic fruit.",
    },
    {
      character: characters.arthur,
      text: "No doubt he took his fill of wine?",
    },
    {
      character: characters.jessica,
      text: "I cannot tell you otherwise. Next, he headed to the stables where he mounted the Lord's steed. Consequently, he was thrown clean off and the animal bolted for the hills. It was then I knew we must leave forever or risk our lives.",
    },
    {
      character: characters.jessica,
      text: "Now he carries a royal rooster under his arm, swiped from the coop whilst leaving the grounds.",
    },
    { character: characters.peter, text: "That's quite the folly." },
    { character: characters.arthur, text: "Then unhand the bird, John." },
    {
      character: characters.john,
      text: "I shan't. This fowl is the property of Lord Ovaltine. For my later consumption, it is.",
    },
    { character: characters.peter, text: "Release the poor thing." },
    {
      character: characters.jessica,
      text: "I'm sorry. He truly believes himself to be the Lord, sir. He won't relinquish it.",
    },
    {
      character: characters.arthur,
      text: "I see. Then I suspect you best be on your way, both of you. The true Lord will surely set his most able knights on your trail for such transgressions.",
    },
    {
      character: characters.jessica,
      text: "I beg of you, forget you ever saw us. Prithee.",
    },
    { character: characters.peter, text: "Make haste, you troublemakers." },
    {
      character: characters.action,
      text: "Peter begins to tap his foot upon the soil as the Ovaltines disappear into the corn",
    },
    { character: characters.peter, text: "Arthur, I too feel peculiar." },
    {
      character: characters.arthur,
      text: "How so? No delusions of grandeur I hope.",
    },
    {
      character: characters.action,
      text: "Peter's foot moves uncontrollably about the dirt",
    },
    {
      character: characters.peter,
      text: "I feel as though a swelling polyphony flows through my body.",
    },
    {
      character: characters.arthur,
      text: "Just all this excitement, I'm sure. It's time we make a move ourselves, lest we fall further into distraction.",
    },
  ],
}
