import { makeAutoObservable, observable, when } from "mobx"
import { RootStore } from "."
import { DialogueConfig, hideDialogue, MessageConfig } from "../config"

export class DialogueStore {
  title: string
  messages: (MessageConfig & { isRead: boolean })[]
  constructor(config: DialogueConfig) {
    this.title = config.title
    this.messages = config.messages.map(message => ({
      ...message,
      isRead: false,
    }))
    makeAutoObservable(this)
  }
  get isRead() {
    return this.messages.every(message => message.isRead)
  }
  next() {
    const nextUnreadItem = this.messages.find(item => !item.isRead)
    if (!nextUnreadItem) return
    this.messages = this.messages.map(item =>
      item === nextUnreadItem ? { ...item, isRead: true } : item
    )
  }
  get nextUnreadMessage() {
    return this.messages.find(message => !message.isRead) || null
  }
}

export class DialoguesStore {
  rootStore: RootStore
  log: DialogueStore[] = []
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { log: observable.shallow })
  }
  say(config: DialogueConfig) {
    const dialogue = new DialogueStore(config)
    this.log = [...this.log, dialogue]
    if (hideDialogue) return Promise.resolve()
    return when(() => dialogue.isRead)
  }
  next() {
    const currentDialogue = this.log.find(dialogue => !dialogue.isRead)
    if (currentDialogue) currentDialogue.next()
  }
  get nextUnreadMessage() {
    const currentDialogue = this.log.find(dialogue => !dialogue.isRead)
    if (currentDialogue) return currentDialogue.nextUnreadMessage
    return null
  }
}
