export const clamp = (x: number, min: number, max: number) =>
  Math.max(Math.min(x, max), min)
export const lerp = (a: number, b: number, t: number) => a + (b - a) * t
export const inverseLerp = (a: number, b: number, x: number) => {
  const range = b - a
  if (range === 0) return a
  return (x - a) / range
}
export const map = (
  x: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number
) => lerp(newMin, newMax, inverseLerp(oldMin, oldMax, x))
export const mapClamped = (
  x: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number
) => clamp(map(x, oldMin, oldMax, newMin, newMax), newMin, newMax)
export const sign = (x: number) => (x > 0 ? 1 : -1)
export const random = (min: number, max: number) =>
  lerp(min, max, Math.random())
export const DEG2RAD = Math.PI / 180
export const RAD2DEG = 180 / Math.PI
export const findMin = <T>(
  iterable: Iterable<T>,
  compare: (item: T) => number
): T | null => {
  let minValue = Infinity
  let minItem: T | null = null
  for (const item of iterable) {
    const value = compare(item)
    if (value < minValue) {
      minValue = value
      minItem = item
    }
  }
  return minItem
}

export const findMax = <T>(arr: T[], compare: (item: T) => number) =>
  findMin(arr, item => -compare(item))

export const shortestAngleDistance = (a: number, b: number) => {
  const diff = ((b - a + Math.PI) % (Math.PI * 2)) - Math.PI
  return diff < -Math.PI ? diff + Math.PI * 2 : diff
}
