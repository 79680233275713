import { makeAutoObservable, observable } from "mobx"
import { music, MusicTrack } from "../config"

export class MusicStore {
  readonly tracks: (MusicTrack & { unlocked: boolean })[]
  constructor() {
    this.tracks = music.map(track => observable({ ...track, unlocked: false }))
    makeAutoObservable(this)
  }
  unlockTrack(track: number) {
    this.tracks[track].unlocked = true
  }
}
