import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_LiveFromCleyHill: DialogueConfig = {
  title: music[3].label,
  messages: [
    {
      character: characters.arthur,
      text: "Whatever's the matter, Peter?",
    },
    {
      character: characters.action,
      text: "Peter downs his scythe and begins to step from side to side",
    },
    { character: characters.arthur, text: "Not this again?" },
    {
      character: characters.peter,
      text: "I'm sorry. The sounds. They've taken me. I cannot help but move so.",
    },
    {
      character: characters.arthur,
      text: "This is no Black Nag, nor Rufty Tufty. No Quadrille, or Farandole. What dance be this?",
    },
    {
      character: characters.peter,
      text: "I follow no steps. The music is my choreographer. I move freely.",
    },
    {
      character: characters.arthur,
      text: "Freely? Yet the movements you cannot resist?",
    },
    {
      character: characters.peter,
      text: "Therein lies the beauty. Does it not?",
    },
    {
      character: characters.arthur,
      text: "You're beginning to worry me.",
    },
    {
      character: characters.action,
      text: "Peter twirls, eyes closed and face full of glee",
    },
    {
      character: characters.arthur,
      text: "Come, Peter. I think I know whomst might provide a remedy.",
    },
  ],
}
