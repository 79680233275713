export type MusicTrack = { label: string; duration: number; src: string }

export const music: MusicTrack[] = [
  {
    label: "Prelude - A Difficult Fog Descends On The Land",
    src: "assets/music/1 Prelude - A Difficult Fog Descends On The Land.mp3",
    duration: 108,
  },
  {
    label: "A Life-Changing Discovery",
    src: "assets/music/2 A Life-Changing Discovery.mp3",
    duration: 229,
  },
  {
    label:
      "We Have To Leave This Town Because I Have Done Something Unforgivable",
    src: "assets/music/3 We Have To Leave This Town Because I Have Done Something Unforgivable.mp3",
    duration: 273,
  },
  {
    label: "Live From Cley Hill",
    src: "assets/music/4 Live From Cley Hill.mp3",
    duration: 225,
  },
  {
    label: "Rest Easy",
    src: "assets/music/5 Rest Easy.mp3",
    duration: 194,
  },
  {
    label: "Haha Fucking QUEST",
    src: "assets/music/6 Haha Fucking QUEST.mp3",
    duration: 200,
  },
  {
    label: "One For The Road",
    src: "assets/music/7 One For The Road.mp3",
    duration: 217,
  },
  {
    label: "Welcome Home Peter Talisman, All Is Forgiven",
    src: "assets/music/8 Welcome Home Peter Talisman, All Is Forgiven.mp3",
    duration: 124,
  },
  {
    label: "The Absolute Scene At Stanton Drew",
    src: "assets/music/9 The Absolute Scene At Stanton Drew.mp3",
    duration: 232,
  },
  {
    label: "Ley U Down",
    src: "assets/music/10 Ley U Down.mp3",
    duration: 242,
  },
  {
    label: "Dance Of The Equinox",
    src: "assets/music/11 Dance Of The Equinox.mp3",
    duration: 314,
  },
  {
    label: "Outro, Show's over, Game over, We Failed, The End",
    src: "assets/music/12 Outro, Show's over, Game over, We Failed, The End.mp3",
    duration: 97,
  },
  {
    label:
      "Tim Wheatsheaf - One More For The Road (Drinking On An Irish Green)",
    src: "assets/music/Tim Wheatsheaf - One More For The Road (Drinking On An Irish Green).mp3",
    duration: 185,
  },
  {
    label: "Gorse Panshawe – Thank You, Pig (For All You Have Done)",
    src: "assets/music/Gorse Panshawe - Thank You, Pig, (For All You Have Done).mp3",
    duration: 190,
  },
]
