import { extendMaterial } from "../../utils/extendMaterial"
import {
  IUniform,
  Matrix4,
  MeshPhysicalMaterial,
  MeshPhysicalMaterialParameters,
  UniformsUtils,
  Vector2,
  Color,
} from "three"
import vertexShader from "./weatherVertex.glsl"
import fragmentShader from "./weatherFragment.glsl"
import { useState } from "react"
import { useWeather } from "./WeatherProvider"

export type WeatherUniforms = {
  time: IUniform<number>
  rain: IUniform<number>
  wet: IUniform<number>
  viewSize: IUniform<Vector2>
  viewMatrixInverse: IUniform<Matrix4>
  projectionMatrixInverse: IUniform<Matrix4>
  fog: IUniform<{ color: Color; height: number; density: number }>
}

export const defaultWeatherUniforms: WeatherUniforms = {
  time: { value: 0 },
  rain: { value: 0 },
  wet: { value: 0 },
  viewSize: { value: new Vector2() },
  viewMatrixInverse: { value: new Matrix4() },
  projectionMatrixInverse: { value: new Matrix4() },
  fog: { value: { height: 0, color: new Color(), density: 0 } },
}

export const MeshWeatherMaterial = extendMaterial(MeshPhysicalMaterial, {
  vertexShader,
  fragmentShader,
  uniforms: UniformsUtils.clone(defaultWeatherUniforms),
})

export const useWeatherMaterial = (
  params: MeshPhysicalMaterialParameters = {}
) => {
  const weatherUniforms = useWeather()
  const [material] = useState(() => {
    const material = new MeshWeatherMaterial(params)
    let key: keyof typeof weatherUniforms
    for (key in weatherUniforms) material.uniforms[key] = weatherUniforms[key]
    return material
  })
  return material
}

export const WeatherMaterial = ({
  args,
  ...props
}: React.ComponentProps<"meshPhysicalMaterial">) => {
  const material = useWeatherMaterial(...(args || []))
  return <primitive object={material} attach="material" {...props} />
}
