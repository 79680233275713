import { MathUtils, Vector2 } from "three"
import { RootStore, PeasantStore } from "."
import { makeObservable, observable } from "mobx"
import { neighbours } from "../utils/tiles"
import { findMax, findMin } from "../utils/math"
import { assert } from "assert-ts"
import {
  BehaviourController,
  BehaviourState,
  INTERRUPT,
  selector,
  startBehaviour,
  TICK,
} from "../utils/behaviour"
import { getUpgradeValue, UpgradeValues } from "../config"

export class DonkeyStore {
  id = MathUtils.generateUUID()
  rootStore: RootStore
  position: Vector2
  altitude = 1
  rotation = 0
  velocity = new Vector2()
  corn = 0
  behaviourController?: BehaviourController
  constructor(rootStore: RootStore, position: Vector2) {
    this.rootStore = rootStore
    this.position = position
    makeObservable(this, {
      position: observable,
      altitude: observable,
      // forwardVelocity: observable,
      // upVelocity: observable,
      // rotationVelocity: observable,
    })
    // makeAutoObservable(this)
  }
  get manager() {
    return this.rootStore.world.workers
  }
  step(dT: number) {
    if (!this.behaviourController)
      this.behaviourController = startBehaviour(this.behaviourRoot.bind(this))
    this.behaviourController(dT)
  }
  getDropOffPoints() {
    if (this.altitude > 0) return []
    return neighbours.map(point => point.clone().add(this.position))
  }
  private reservations = new Set<PeasantStore>()
  get reservedCapacity() {
    return [...this.reservations].reduce(
      (total, peasant) => total + peasant.corn,
      0
    )
  }
  hasReservation(peasant: PeasantStore) {
    return this.reservations.has(peasant)
  }
  requestReservation(peasant: PeasantStore) {
    assert(this.altitude === 0)
    if (this.reservations.has(peasant)) return true
    if (this.corn + this.reservedCapacity < this.capacity) {
      this.reservations.add(peasant)
      return true
    }
    const furthest = findMax([...this.reservations, peasant], peasant =>
      peasant.position.distanceToSquared(this.position)
    )!
    if (peasant !== furthest) {
      this.reservations.delete(furthest)
      this.reservations.add(peasant)
      return true
    } else {
      return false
    }
  }
  cancelReservation(peasant: PeasantStore) {
    this.reservations.delete(peasant)
  }
  collectFromPeasant(peasant: PeasantStore) {
    assert(this.reservations.has(peasant))
    assert(this.corn < this.capacity)
    const max = this.capacity - this.corn
    if (peasant.corn < max) {
      this.corn += peasant.corn
      peasant.corn = 0
    } else {
      this.corn += max
      peasant.corn -= max
    }
    if (this.corn + this.reservedCapacity >= this.capacity) {
      const closestReservations = [...this.reservations].sort(
        (a, b) =>
          a.position.distanceToSquared(this.position) -
          b.position.distanceToSquared(this.position)
      )
      let filledCapacity = this.corn
      for (const peasant of closestReservations) {
        if (filledCapacity >= this.capacity) this.cancelReservation(peasant)
        filledCapacity += peasant.corn
      }
    }
  }
  behaviourRoot(state: BehaviourState) {
    const collect = this.collect.bind(this)
    const deposit = this.deposit.bind(this)
    return selector(state, () => {
      // if (shouldDance) return dance
      return this.corn < this.capacity ? collect : deposit
    })
  }
  collectionLocation: Vector2 | null = null;
  *collect(state: BehaviourState) {
    try {
      if (this.reservations.size > 0) {
        yield TICK
      } else {
        let timeUntilRefreshLocation = 0
        while (true) {
          if (timeUntilRefreshLocation <= 0) {
            this.collectionLocation = this.getNewCollectionLocation()
            timeUntilRefreshLocation = 5
          }
          if (this.collectionLocation !== null) {
            this.move(this.collectionLocation, state.timeRemaining)
          }
          timeUntilRefreshLocation -= state.timeRemaining
          yield INTERRUPT
          yield TICK
        }
      }
    } finally {
      this.collectionLocation = null
    }
  }
  getCollectionLocation() {
    const v = new Vector2()
    const map = this.rootStore.world.map
    const beaconPosition = this.rootStore.world.beacon.position
    const bestHarvestableTile = findMin(this.manager.harvestableTiles, index =>
      map.toCoord(index, v).distanceToSquared(beaconPosition)
    )
    if (!bestHarvestableTile) return null
    const bestHarvestLocation = this.manager.getHarvestLocation(
      map.toCoord(bestHarvestableTile, v)
    )
    const otherLocations = this.manager.donkeys
      .filter(donkey => donkey !== this && donkey.collectionLocation !== null)
      .map(donkey => donkey.collectionLocation!)
    const clearTiles: boolean[] = []
    const tileIsClear = (tileIndex: number) => {
      let isClear = clearTiles[tileIndex]
      if (isClear === undefined) {
        isClear = clearTiles[tileIndex] =
          !map.isSolid(tileIndex) &&
          !otherLocations.some(location => map.toIndex(location) === tileIndex)
      }
      return isClear
    }
    const canLandAt = (tileIndex: number) =>
      tileIsClear(tileIndex) &&
      map.getNeighbourIndexes(tileIndex, true).every(tileIsClear)
    for (const index of map.floodFill(
      bestHarvestLocation,
      index => !map.isSolid(index)
    )) {
      if (canLandAt(index)) {
        return map.toCoord(index, v)
      }
    }
    return null
  }
  getNewCollectionLocation() {
    const newLocation = this.getCollectionLocation()
    if (newLocation === null) {
      return this.collectionLocation
    } else if (this.collectionLocation === null) {
      return newLocation
    } else if (
      this.getScoreFor(newLocation) >
      this.getScoreFor(this.collectionLocation) * 5
    ) {
      return newLocation
    } else {
      return this.collectionLocation
    }
  }
  canLandAt(tileIndex: number) {
    const map = this.rootStore.world.map
    const otherLocations = this.manager.donkeys
      .filter(donkey => donkey !== this && donkey.collectionLocation !== null)
      .map(donkey => donkey.collectionLocation!)
    const tileIsClear = (tileIndex: number) =>
      !map.isSolid(tileIndex) &&
      !otherLocations.some(location => map.toIndex(location) === tileIndex)
    return (
      tileIsClear(tileIndex) &&
      map.getNeighbourIndexes(tileIndex, true).every(tileIsClear)
    )
  }
  getScoreFor(coord: Vector2) {
    const map = this.rootStore.world.map
    const v = new Vector2()
    return Math.max(
      ...this.manager.priorityHarvestTargets.map(
        ({ index, score }) =>
          score * (1 / map.toCoord(index, v).distanceToSquared(coord))
      )
    )
  }
  *deposit(state: BehaviourState) {
    while (true) {
      const destination = findMin(
        this.rootStore.world.granary.getDropOffPoints(),
        coord => coord.distanceToSquared(this.position)
      )
      if (destination !== null) {
        this.move(destination, state.timeRemaining)
        if (this.position.equals(destination) && this.altitude === 0) break
      }
      yield INTERRUPT
      yield TICK
    }
    this.rootStore.player.addCorn(this.corn)
    this.corn = 0
  }
  move(to: Vector2, dT: number) {
    const arrivalRadius = 5
    const maxForce = this.moveSpeed / 40
    const delta = to.clone().sub(this.position)
    const distance = delta.length()
    if (distance < 0.05) {
      this.position = to
      this.velocity = new Vector2(0, 0)
      if (this.altitude > 0) {
        this.altitude = Math.max(this.altitude - this.moveSpeed * dT * 0.05, 0)
      }
    } else {
      if (this.altitude < 1) {
        this.altitude = Math.min(this.altitude + this.moveSpeed * dT * 0.05, 1)
        return
      }
      const arrival = Math.min(delta.length() / arrivalRadius, 1)
      const targetVelocity = delta
        .clone()
        .normalize()
        .multiplyScalar(this.moveSpeed * arrival)
      const steering = targetVelocity.clone().sub(this.velocity)
      if (steering.length() > maxForce) steering.setLength(maxForce)
      this.velocity.add(steering)
      if (this.velocity.length() > this.moveSpeed)
        this.velocity.setLength(this.moveSpeed)
      this.position = this.position
        .clone()
        .add(this.velocity.clone().multiplyScalar(dT))
    }
    // this.altitude = this.velocity.length() / this.moveSpeed
  }
  static moveSpeedUpgrades: UpgradeValues = {
    base: 20,
    upgrades: [
      { id: "donkeyMove1", value: 25 },
      { id: "donkeyMove2", value: 28 },
      { id: "donkeyMove3", value: 33 },
      { id: "donkeyMove4", value: 37 },
      { id: "donkeyMove5", value: 42 },
      { id: "donkeyMove6", value: 50 },
      { id: "donkeyMove7", value: 60 },
    ],
  }
  static capacityUpgrades: UpgradeValues = {
    base: 20,
    upgrades: [
      { id: "donkeyCapacity1", value: 25 },
      { id: "donkeyCapacity2", value: 30 },
      { id: "donkeyCapacity3", value: 35 },
      { id: "donkeyCapacity4", value: 40 },
      { id: "donkeyCapacity5", value: 45 },
      { id: "donkeyCapacity6", value: 50 },
      { id: "donkeyCapacity7", value: 55 },
    ],
  }
  get moveSpeed() {
    return getUpgradeValue(this.rootStore, DonkeyStore.moveSpeedUpgrades)
  }
  get capacity() {
    return getUpgradeValue(this.rootStore, DonkeyStore.capacityUpgrades)
  }
}
