import { Box, ChakraProvider } from "@chakra-ui/react"
import { theme } from "../../styles/theme"
import { lazy, useState, Suspense } from "react"
import { TitleScreen } from "../TitleScreen"
import { DetectMobile } from "./DetectMobile"

const GameLoader = lazy(() =>
  import("../Game").then(module => ({ default: module.Game }))
)

export const App = () => {
  const [gameReady, setGameReady] = useState(false)
  const [gameStarting, setGameStarting] = useState(false)
  const [fadeOutTitle, setFadeOutTitle] = useState(false)
  const [panDown, setPanDown] = useState(false)
  const [gameStarted, setGameStarted] = useState(false)

  const startGame = async () => {
    if (gameStarting) return
    setGameStarting(true)
    setFadeOutTitle(true)
    await new Promise(r => setTimeout(r, 1000))
    setPanDown(true)
    await new Promise(r => setTimeout(r, 5000))
    setGameStarted(true)
  }

  return (
    <ChakraProvider theme={theme}>
      <DetectMobile>
        <Box
          background="url(assets/stars.png) center center"
          backgroundSize="cover"
          position="fixed"
          layerStyle="fill"
          transition="transform 5s"
          transform={panDown ? "translateY(-100%)" : "none"}
          sx={{
            "&:after": {
              content: "''",
              layerStyle: "fill",
              background: "linear-gradient(to bottom, rgba(0,0,0,0), black)",
            },
          }}
        >
          <TitleScreen
            loaded={gameReady}
            start={startGame}
            opacity={fadeOutTitle ? 0 : 1}
            layerStyle="fill"
            transition="opacity 1s"
          />
        </Box>
        <Box
          position="fixed"
          layerStyle="fill"
          transition="transform 5s"
          transform={panDown ? "none" : "translateY(100%)"}
        >
          <Suspense fallback={null}>
            <GameLoader
              onReady={() => setGameReady(true)}
              gameStarted={gameStarted}
            />
          </Suspense>
        </Box>
      </DetectMobile>
    </ChakraProvider>
  )
}
