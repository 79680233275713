import { makeAutoObservable, observable } from "mobx"
import { ObjectiveConfig, ObjectiveID, objectives } from "../config/objectives"

export class ObjectivesStore {
  currentObjectives: Partial<Record<ObjectiveID, ObjectiveConfig>> = {}
  private completed: Partial<Record<ObjectiveID, boolean>> = {}
  constructor() {
    makeAutoObservable(this, { currentObjectives: observable.shallow })
  }
  add(id: ObjectiveID) {
    this.currentObjectives = { ...this.currentObjectives, [id]: objectives[id] }
  }
  isActive(id: ObjectiveID) {
    return id in this.currentObjectives
  }
  complete(id: ObjectiveID) {
    const objectives = { ...this.currentObjectives }
    delete objectives[id]
    this.currentObjectives = objectives
    this.completed = { ...this.completed, [id]: true }
  }
  isComplete(id: ObjectiveID) {
    return id in this.completed
  }
}
