import { autorun, makeAutoObservable, when } from "mobx"
import { Vector2 } from "three"
import { RootStore, AudioStore, LoreObjectStore } from "."
import { getNeighboursInRadiusCircular } from "../utils/tiles"
import { IWorldObject } from "./WorldStore"
import { ObservableTransition } from "../utils/ObservableTransition"
import { hideDialogue, hideTitles, testMode } from "../config"
import { tween } from "../utils/tween"
import { ObservableSpring } from "../utils/ObservableSpring"
import * as eases from "eases"
import { GamePhase } from "./RootStore"

export type PointerTarget =
  | { type: "none" }
  | { type: "ui" }
  | { type: "map" }
  | { type: "object"; object: IWorldObject; getMessage?: () => string | null }

type HoldableObject = IWorldObject

export type TitleState = { chapter: number; opacity: number }

const transitionInDuration = hideTitles ? 0 : 2000
const waitDuration = hideTitles ? 0 : 3000
const transitionOutDuration = hideTitles ? 0 : 3000

enum TransitionState {
  Entering = 0,
  Entered = 1,
  Exiting = 2,
  Exited = 3,
}

class TitleTransition {
  chapter: number
  opacity = 0
  state: TransitionState = TransitionState.Entering
  constructor(chapter: number) {
    this.chapter = chapter
    makeAutoObservable(this)
    this.animate()
  }
  setOpacity(opacity: number) {
    this.opacity = opacity
  }
  *animate() {
    yield tween(transitionInDuration, t => this.setOpacity(eases.cubicOut(t)))
    this.state = TransitionState.Entered
    yield new Promise(resolve => setTimeout(resolve, waitDuration))
    this.state = TransitionState.Exiting
    yield tween(transitionOutDuration, t =>
      this.setOpacity(1 - eases.cubicIn(t))
    )
    this.state = TransitionState.Exited
  }
  onState(state: TransitionState) {
    if (this.state >= state) return Promise.resolve()
    const promise = when(() => this.state === state)
    this.disposers.push(() => promise.cancel())
    return promise
  }
  entered() {
    return this.onState(TransitionState.Entered)
  }
  exiting() {
    return this.onState(TransitionState.Exiting)
  }
  exited() {
    return this.onState(TransitionState.Exited)
  }
  disposers: (() => void)[] = []
  dispose() {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }
}

export class UIStore {
  rootStore: RootStore
  audio: AudioStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.audio = new AudioStore(this.rootStore, 0)
    makeAutoObservable(this)
  }

  init() {
    this.bindUiOpacity()
    this.bindFullBagObjective()
    this.initRippleTransition()
  }

  uiOpacitySpring = new ObservableSpring({ fromValue: 0 })
  modalOpacitySpring = new ObservableSpring({ fromValue: 0 })
  bindUiOpacity() {
    this.disposers.push(
      autorun(() => {
        this.uiOpacitySpring.to(this.hideUI ? 0 : 1)
      }),
      autorun(() => {
        this.modalOpacitySpring.to(this.showModal ? 1 : 0)
      })
    )
  }
  get hideUI() {
    return (
      this.rootStore.gamePhase <= GamePhase.Intro ||
      this.rootStore.gamePhase === GamePhase.Dance ||
      this.heldObject !== null ||
      (!this.cameraIsLocked && this.cameraIsDragging) ||
      this.showModal
    )
  }
  get uiOpacity() {
    return Math.min(this.uiOpacitySpring.value, 1 - this.titleOpacity)
  }
  get showModal() {
    return (
      !(
        this.rootStore.gamePhase <= GamePhase.Intro ||
        this.rootStore.gamePhase === GamePhase.Dance
      ) &&
      ((!hideDialogue && this.rootStore.dialogues.nextUnreadMessage !== null) ||
        this.currentLoreObject !== null ||
        this.showVideo)
    )
  }
  get modalOpacity() {
    return Math.min(this.modalOpacitySpring.value, 1 - this.titleOpacity)
  }

  currentTitle: TitleTransition | null = null
  showTitle(chapter: number) {
    const title = new TitleTransition(chapter)
    this.currentTitle = title
    title.exited().then(() => {
      if (this.currentTitle === title) this.currentTitle = null
    })
    return title
  }
  get titleOpacity() {
    if (this.currentTitle === null) return 0
    return this.currentTitle.opacity
  }

  pointerPositionWorld = new Vector2()
  setPointerPositionWorld(position: Vector2) {
    this.pointerPositionWorld = position
  }
  get pointerPositionTile() {
    return this.pointerPositionWorld.clone().floor()
  }

  pointerTarget: PointerTarget = { type: "none" }
  setPointerTarget(target: PointerTarget) {
    this.pointerTarget = target
  }
  get targetObject() {
    if (this.pointerTarget.type !== "object") return null
    return this.pointerTarget.object
  }

  heldObject: HoldableObject | null = null
  setHeldObject(object: HoldableObject | null) {
    this.heldObject = object
  }

  get harvestableTile() {
    if (this.heldObject) return null
    if (this.pointerTarget.type !== "map") return null
    if (this.rootStore.player.isHeldResourcesFull) return null
    const center = this.pointerPositionTile
    const player = this.rootStore.player
    const map = this.rootStore.world.map
    if (player.canHarvest(center)) return center
    const coord = new Vector2()
    for (const neighbourOffset of UIStore.harvestableNeighbours) {
      coord.copy(center).add(neighbourOffset)
      if (map.isInBounds(coord) && player.canHarvest(coord)) return coord
    }
    return null
  }
  static harvestableNeighbours = getNeighboursInRadiusCircular(3).sort(
    (a, b) => a.lengthSq() - b.lengthSq()
  )

  rippleTransition?: ObservableTransition<Vector2 | null, string>
  initRippleTransition() {
    this.rippleTransition = new ObservableTransition({
      get: () => {
        if (!this.audio.playing) return null
        const track = this.audio.currentTrack
        if (track === null) return null
        const stone = this.rootStore.world.standingStones.find(
          stone => stone.track === track
        )
        if (!stone) return null
        return stone.position
      },
      key: v => (v === null ? "null" : `${v.x} ${v.y}`),
    })
  }
  get rippleUniforms() {
    if (!this.rippleTransition)
      throw new Error("Ripple transition not initialized")
    const items = this.rippleTransition.items.filter(
      item => item.value !== null
    )
    if (!items.length)
      return {
        rippleCenter: new Vector2(),
        rippleStrength: 0,
      }
    const max =
      items.length === 1
        ? items[0]
        : items.reduce((max, item) => (item.t > max.t ? item : max))
    return {
      rippleCenter: max.value!,
      rippleStrength: Math.max(max.t * 2 - 1, 0),
    }
  }

  cameraIsDragging = false

  get cameraIsLocked() {
    if (testMode) return false
    if (
      this.rootStore.world.numClaimedStandingStones === 0 ||
      this.rootStore.gamePhase === GamePhase.Dance
    )
      return true
    return false
  }

  fullBagClicks = 0
  incrementFullBagClicks() {
    this.fullBagClicks++
  }
  clearFullBagClicks() {
    this.fullBagClicks = 0
  }
  bindFullBagObjective() {
    this.disposers.push(
      autorun(() => {
        if (this.fullBagClicks >= 10)
          this.rootStore.objectives.add("returnCorn")
      })
    )
  }

  currentLoreObject: LoreObjectStore | null = null
  showLore(object: LoreObjectStore | null) {
    this.currentLoreObject = object
  }

  theSubliminalCatalogueOpen = false
  setTheSubliminalCatalogue(bool: boolean) {
    this.theSubliminalCatalogueOpen = bool
  }

  storyOpen = false
  setStory(bool: boolean) {
    this.storyOpen = bool
  }
  get isStoryOpen() {
    return this.storyOpen
  }

  showVideo = false
  setShowVideo(showVideo: boolean) {
    this.showVideo = showVideo
  }

  disposers: (() => void)[] = []
  dispose() {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
    this.uiOpacitySpring.stop()
    this.modalOpacitySpring.stop()
    this.currentTitle?.dispose()
    this.audio.dispose()
  }
}
