export type Credit = { name: string; role: string; url?: string }

const game: Credit[] = [
  {
    name: "Ben West",
    role: "Design, Programming, Corn, UI",
    url: "https://bewe.me/",
  },
  {
    name: "Joseph Pleass",
    role: "Design, Programming, UI",
    url: "https://josephpleass.com/",
  },
  {
    name: "Callum Copley",
    role: "Writing",
    url: "http://callumcopley.com/",
  },
  {
    name: "Leanne Bentley",
    role: "3D Artwork",
    url: "https://leannebentley.co.uk/",
  },
  {
    name: "David Renton",
    role: "Peasants",
    url: "https://www.holypeaches.com/",
  },
  {
    name: "Armando de Cosmos",
    role: "Chalk Cutting",
    url: "https://www.instagram.com/arma.ndo.2021/",
  },
  {
    name: "Elliott Elder",
    role: "Additional Artwork",
    url: "https://elliottelder.co.uk/",
  },
]

const music: Credit[] = [
  { name: "Peter Talisman", role: "Acoustoplux 500, Solar Drum, Vocals." },
  {
    name: "Tim Wheatsheaf",
    role: "Spectral Pipesack, Duotronic Flute, Vocals.",
  },
  { name: "John Ovaltine", role: "Nanowave, Firechord Poly, Steam Piano." },
  {
    name: "Gorse Panshawe",
    role: "Jangly Beads, Wooden Spoon, Emergency Pulse Bypass.",
  },
  {
    name: "Arthur Portal-Dolmen",
    role: "Samples From Elsewhere / Subliminal Catalogue Technician (Wizardry).",
  },
  {
    name: "Roger Mandrake",
    role: "Poison Bass Unit, Super Nova Controller, Biotic Pickups.",
  },
  {
    name: "Keith Demon",
    role: "Drum Kit, VLORT V-8 Rhythm Designer, Upturned Pail",
  },
]

const specialThanks =
  "Leon of Fedden, Faxton Choir, John & Ann at the Marquess & Swan of Slempton Bridge, Little Thessleton Woodland Walking Club (for use of the village hall), Ian at The Viscount of Old at Sherforth-on-the-Wye (you genuinely saved us!), Samuel Organ & Gregory Feldwick"

export const credits = { game, music, specialThanks }
