import { useEffect, useState } from "react"
import {
  IUniform,
  Mesh,
  OrthographicCamera,
  ShaderMaterial,
  Scene,
  Vector2,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three"
import { useStore } from "../StoreProvider"
import { HalfFloatType } from "three"
import { planeGeometry } from "../../utils/primitives"
import vertexShader from "../../utils/shaders/fullscreenVertex.glsl"
import fragmentShader from "./windFragment.glsl"
import { useFrame } from "@react-three/fiber"

class TextureRenderer<Uniforms extends Record<string, IUniform>> {
  private renderTarget: WebGLRenderTarget
  private material: ShaderMaterial
  private scene: Scene
  private camera: OrthographicCamera
  constructor(
    width: number,
    height: number,
    fragmentShader: string,
    uniforms: Uniforms
  ) {
    this.renderTarget = new WebGLRenderTarget(width, height, {
      type: HalfFloatType,
    })
    this.material = new ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms,
    })
    this.scene = new Scene()
    this.scene.add(new Mesh(planeGeometry, this.material))
    this.camera = new OrthographicCamera(-0.5, 0.5, 0.5, -0.5)
    this.camera.position.z = 5
  }
  get uniforms() {
    return this.material.uniforms as Uniforms
  }
  get texture() {
    return this.renderTarget.texture
  }
  render(renderer: WebGLRenderer) {
    renderer.setRenderTarget(this.renderTarget)
    renderer.render(this.scene, this.camera)
    renderer.setRenderTarget(null)
  }
  dispose() {
    return this.renderTarget.dispose()
  }
}

export const useWindRenderer = (size: Vector2) => {
  const rootStore = useStore()
  const [renderer] = useState(
    () =>
      new TextureRenderer(size.x, size.y, fragmentShader, {
        rippleCenter: { value: new Vector2() },
        rippleStrength: { value: 0 },
        windOffset: { value: new Vector2() },
        time: { value: 0 },
      })
  )
  useFrame(({ gl }) => {
    renderer.uniforms.time.value = rootStore.time
    const rippleUniforms = rootStore.ui.rippleUniforms
    renderer.uniforms.rippleCenter.value = rippleUniforms.rippleCenter
    renderer.uniforms.rippleStrength.value = rippleUniforms.rippleStrength
    renderer.render(gl)
  }, -1)
  useEffect(() => {
    return () => {
      renderer.dispose()
    }
  }, [renderer])
  return renderer.texture
}
