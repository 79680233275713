import { createContext, useContext, useEffect, useState } from "react"
import { RootStore, Assets } from "../../store"
import { bindAnalytics } from "../../utils/analytics"

export const StoreContext = createContext<RootStore | null>(null)

export const useLoadStore = () => {
  const [assets] = useState(() => new Assets())
  const [assetsLoaded, setAssetsLoaded] = useState(false)

  useEffect(() => {
    let cancelled = false
    assets.loadAll().then(() => {
      if (!cancelled) setAssetsLoaded(true)
    })
    return () => {
      cancelled = true
    }
  })

  const [store, setStore] = useState<RootStore | null>(null)

  useEffect(() => {
    if (assetsLoaded) setStore(new RootStore(assets))
  }, [assets, assetsLoaded])

  useEffect(() => {
    if (!assetsLoaded || !store) return
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "r") {
        store.dispose()
        setStore(new RootStore(assets))
      }
    }
    window.addEventListener("keydown", onKeyDown)
    return () => window.removeEventListener("keydown", onKeyDown)
  }, [assets, assetsLoaded, store])

  useEffect(() => {
    if (store === null) return
    return bindAnalytics(store)
  }, [store])

  useEffect(() => {
    if (!store) return
    let timeHidden: number | null = null
    const onVisibilityChange = async () => {
      if (document.hidden) {
        timeHidden = Date.now() / 1000
      } else {
        if (timeHidden === null || store.paused) return
        const timeElapsed = Math.min(
          Date.now() / 1000 - timeHidden,
          60 * 60 * 2
        )
        store.pause()
        await store.advance(timeElapsed)
        store.resume()
      }
    }
    document.addEventListener("visibilitychange", onVisibilityChange)
    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange)
  }, [store])

  useEffect(() => {
    if (!store) return
    if (process.env.NODE_ENV !== "development") return
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === " ") {
        store.pause()
        store.advance(60 * 5)
        store.resume()
      } else if (e.key === "k") {
        const nextStone = store.world.standingStones.find(
          stone => !stone.isClaimed
        )
        nextStone?.claim()
      }
    }
    window.addEventListener("keydown", onKeyDown)
    return () => window.removeEventListener("keydown", onKeyDown)
  }, [store])

  return store
}

export const useStore = () => {
  const store = useContext(StoreContext)
  if (!store) throw new Error("useStore: no store")
  return store
}

export const StoreProvider = StoreContext.Provider
