import { useState, ReactNode } from "react"
import { Box, Stack, Text } from "@chakra-ui/react"
import { LatticeBorder } from "../UI/Borders"

export const DetectMobile = ({ children }: { children: ReactNode }) => {
  const [isMobile] = useState(
    () => "ontouchstart" in window || window.innerWidth <= 768
  )
  if (isMobile) {
    return (
      <Box position="fixed" layerStyle="fill" padding={4}>
        <LatticeBorder
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack textAlign="center">
            <Text>Begone!</Text>
            <Text>Please play this game on a desktop or laptop computer.</Text>
          </Stack>
        </LatticeBorder>
      </Box>
    )
  } else {
    return <>{children}</>
  }
}
