import { createBreakpoints } from "@chakra-ui/theme-tools"
import "./fonts"

export const lineHeight = 1.4
export const rem = [13, 13, 13, 15]
export const line = rem.map(x => x * lineHeight)
export const step = 1 / 4
export const unit = lineHeight * step

export const pageMargin = [3, null, null, 4]

const range = (n: number, unit: (n: number) => string) => {
  const values: Record<number, string> = {}
  Array(n + 1)
    .fill(0)
    .forEach((_, i) => (values[i] = unit(i)))
  return values
}

const space = range(40, i => unit * i + "rem")

export const theme = {
  config: {
    useSystemColorMode: false,
  },
  styles: {
    global: (props: any) => ({
      "*": {
        position: "relative",
        wordWrap: "break-word",
        userSelect: "none",
      },
      html: {
        fontSize: rem.map(x => x + "px"),
        lineHeight: props.theme.lineHeights.default,
        fontFamily: props.theme.fonts.default,
        textUnderlineOffset: "0.085em",
        backgroundColor: props.theme.colors.background,
        color: props.theme.colors.text,
        overscrollBehavior: "none",
        fontDisplay: "block",
        WebkitOverflowScrolling: "touch",
        overflow: "hidden",
        WebkitFontSmoothing: "none",
        letterSpacing: "0.015em",
      },
      "html, body, #root": { height: "100%" },
      "input, textarea": {
        borderRadius: 0,
      },
      img: { border: 0, display: "block" },
    }),
  },
  breakpoints: createBreakpoints({
    sm: "768px",
    md: "1024px",
    lg: "1200px",
    xl: "1440px",
  }),
  lineHeights: {
    default: lineHeight,
  },
  fonts: {
    default: "Monarch, serif",
  },
  colors: {
    yellow: "#D4B030",
    background: "black",
    text: "white",
  },
  space,
  sizes: space,
  radii: space,
  layerStyles: {
    fill: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    shadow: {
      "&:before": {
        content: "''",
        display: "block",
        backgroundColor: "var(--shadow-color, black)",
        transition: "background-color 0.75s",
        position: "absolute",
        top: "-0.125em",
        left: "-0.125em",
        bottom: "-0.125em",
        right: "-0.125em",
        filter: "var(--shadow-blur, blur(4px))",
        zIndex: -1,
      },
    },
  },
  textStyles: {},
  components: {},
}
