import { DonkeyStore, PeasantStore, RootStore } from "../store"
import { seededRandom } from "../utils/seededRandom"

export type UpgradeValues = {
  base: number
  upgrades: { id: UpgradeID; value: number }[]
}

export const getUpgradeValue = (
  rootStore: RootStore,
  values: UpgradeValues
) => {
  const upgrades = rootStore.shop.upgrades
  for (let i = values.upgrades.length - 1; i >= 0; i--) {
    const { id, value } = values.upgrades[i]
    if (upgrades[id].bought) return value
  }
  return values.base
}

export interface ShopItemConfig {
  label: string | ((rootStore: RootStore) => string)
  cost: number | ((rootStore: RootStore) => number)
  available?: (rootStore: RootStore) => boolean
  buy?: (rootStore: RootStore) => void
  buyMultiple?: boolean
  subtext?: (rootStore: RootStore) => string | null
}

export type WorkerID = "peasant" | "donkey"

const peasantVerbs = [
  "Hire",
  "Train",
  "Bribe",
  "Recruit",
  "Secure",
  "Draft",
  "Find",
  "Enlist",
  "Employ",
  "Procure",
  "Involve",
  "Bewitch",
  "Have",
  "Busy",
  "Hatch",
  "Summon",
  "Rally",
  "Muster",
]
const donkeyVerbs = [
  "Make",
  "Build",
  "Construct",
  "Fashion",
  "Manufacture",
  "Produce",
  "Fabricate",
  "Assemble",
  "Whip Out",
  "Mobilize",
  "Activate",
  "Ready",
  "Contrive",
  "Rig Up",
  "Devise",
]
const pickVerb = (verbs: string[], n: number) =>
  verbs[Math.floor(seededRandom(n)(0, verbs.length))]

export const workers: Record<WorkerID, ShopItemConfig> = {
  peasant: {
    label: rootStore => {
      const count = rootStore.world.workers.peasants.length
      const verb = count < 10 ? peasantVerbs[0] : pickVerb(peasantVerbs, count)
      return `${verb} a Peasant`
    },
    cost: rootStore =>
      Math.floor(10 * 1.07 ** rootStore.world.workers.peasants.length),
    available: rootStore => true,
    buy: rootStore => rootStore.world.workers.createPeasant(),
    subtext: rootStore => {
      if (!rootStore.shop.technology.workshop.bought) return null
      const speed = getUpgradeValue(rootStore, PeasantStore.moveSpeedUpgrades)
      const capacity = getUpgradeValue(rootStore, PeasantStore.capacityUpgrades)
      return `Speed: ${speed} • Capacity: ${capacity}`
    },
    buyMultiple: true,
  },
  donkey: {
    label: rootStore => {
      const count = rootStore.world.workers.donkeys.length
      const verb = count < 1 ? donkeyVerbs[0] : pickVerb(donkeyVerbs, count)
      return `${verb} a Donkey`
    },
    cost: rootStore =>
      Math.floor(200 * 1.07 ** rootStore.world.workers.donkeys.length),
    available: rootStore => rootStore.shop.technology.factory.bought,
    buy: rootStore => rootStore.world.workers.createDonkey(),
    subtext: rootStore => {
      const speed = getUpgradeValue(rootStore, DonkeyStore.moveSpeedUpgrades)
      const capacity = getUpgradeValue(rootStore, DonkeyStore.capacityUpgrades)
      return `Speed: ${speed} • Capacity: ${capacity}`
    },
    buyMultiple: true,
  },
}

export type TechnologyID = "beacon" | "workshop" | "factory"
export const technology: Record<TechnologyID, ShopItemConfig> = {
  beacon: {
    label: "Buy the Beacon",
    cost: 75,
    available: rootStore =>
      rootStore.shop.technology.workshop.bought &&
      !rootStore.world.beacon.enabled,
    buy: rootStore => (rootStore.world.beacon.enabled = true),
  },
  workshop: {
    label: "Build the Workshop",
    cost: 75,
    available: rootStore => rootStore.chapter >= 1,
  },
  factory: {
    label: "Build the Donkey Sanctuary",
    cost: 300,
    available: rootStore =>
      rootStore.shop.technology.workshop.bought && rootStore.chapter >= 3,
  },
}

export type UpgradeID =
  | "peasantCapacity1"
  | "peasantCapacity2"
  | "peasantCapacity3"
  | "peasantCapacity4"
  | "peasantCapacity5"
  | "peasantCapacity6"
  | "peasantCapacity7"
  | "peasantMove1"
  | "peasantMove2"
  | "peasantMove3"
  | "peasantMove4"
  | "peasantMove5"
  | "peasantMove6"
  | "peasantMove7"
  | "peasantMove8"
  | "peasantHarvest"
  | "donkeyCapacity1"
  | "donkeyCapacity2"
  | "donkeyCapacity3"
  | "donkeyCapacity4"
  | "donkeyCapacity5"
  | "donkeyCapacity6"
  | "donkeyCapacity7"
  | "donkeyMove1"
  | "donkeyMove2"
  | "donkeyMove3"
  | "donkeyMove4"
  | "donkeyMove5"
  | "donkeyMove6"
  | "donkeyMove7"

const getUpgradeMultiplier = (upgrades: UpgradeValues, index: number) => {
  return upgrades.upgrades[index].value
}
const peasantCapacityText = (index: number) =>
  `Increase capacity to ${getUpgradeMultiplier(
    PeasantStore.capacityUpgrades,
    index
  )}`
const peasantSpeedText = (index: number) =>
  `Increase speed to ${getUpgradeMultiplier(
    PeasantStore.moveSpeedUpgrades,
    index
  )}`

const donkeyCapacityText = (index: number) =>
  `Increase capacity to ${getUpgradeMultiplier(
    DonkeyStore.capacityUpgrades,
    index
  )}`
const donkeySpeedText = (index: number) =>
  `Increase speed to ${getUpgradeMultiplier(
    DonkeyStore.moveSpeedUpgrades,
    index
  )}`

const peasantCapacityUpgradeCost = (n: number) => Math.floor(175 * 1.75 ** n)

const peasantSpeedUpgradeCost = (n: number) => Math.floor(100 * 1.45 ** n)

const donkeyCapacityUpgradeCost = (n: number) => Math.floor(400 * 1.75 ** n)

const donkeySpeedUpgradeCost = (n: number) => Math.floor(200 * 1.5 ** n)

export const upgrades: Record<UpgradeID, ShopItemConfig> = {
  peasantCapacity1: {
    label: "Medium Peasant Bag I",
    cost: peasantCapacityUpgradeCost(0),
    available: rootStore => rootStore.shop.technology.workshop.bought,
    subtext: () => peasantCapacityText(0),
  },
  peasantCapacity2: {
    label: "Large Peasant Bag II",
    cost: peasantCapacityUpgradeCost(1),
    available: rootStore => rootStore.shop.upgrades.peasantCapacity1.bought,
    subtext: () => peasantCapacityText(1),
  },
  peasantCapacity3: {
    label: "Massive Peasant Bag III",
    cost: peasantCapacityUpgradeCost(2),
    available: rootStore => rootStore.shop.upgrades.peasantCapacity2.bought,
    subtext: () => peasantCapacityText(2),
  },
  peasantCapacity4: {
    label: "Enourmous Peasant Bag IV",
    cost: peasantCapacityUpgradeCost(3),
    available: rootStore => rootStore.shop.upgrades.peasantCapacity3.bought,
    subtext: () => peasantCapacityText(3),
  },
  peasantCapacity5: {
    label: "Ridiculous Peasant Bag V",
    cost: peasantCapacityUpgradeCost(4),
    available: rootStore => rootStore.shop.upgrades.peasantCapacity4.bought,
    subtext: () => peasantCapacityText(4),
  },
  peasantCapacity6: {
    label: "Gargantuan Peasant Bag V",
    cost: peasantCapacityUpgradeCost(5),
    available: rootStore => rootStore.shop.upgrades.peasantCapacity5.bought,
    subtext: () => peasantCapacityText(5),
  },
  peasantCapacity7: {
    label: "Humongous Peasant Bag VI",
    cost: peasantCapacityUpgradeCost(6),
    available: rootStore => rootStore.shop.upgrades.peasantCapacity6.bought,
    subtext: () => peasantCapacityText(6),
  },
  peasantMove1: {
    label: "Reasonable Peasant Shoes I",
    cost: peasantSpeedUpgradeCost(0),
    available: rootStore => rootStore.shop.technology.workshop.bought,
    subtext: () => peasantSpeedText(0),
  },
  peasantMove2: {
    label: "Nice Peasant Shoes II",
    cost: peasantSpeedUpgradeCost(1),
    available: rootStore => rootStore.shop.upgrades.peasantMove1.bought,
    subtext: () => peasantSpeedText(1),
  },
  peasantMove3: {
    label: "Tactical Peasant Boots III",
    cost: peasantSpeedUpgradeCost(2),
    available: rootStore => rootStore.shop.upgrades.peasantMove2.bought,
    subtext: () => peasantSpeedText(2),
  },
  peasantMove4: {
    label: "Advanced Peasant Boots IV",
    cost: peasantSpeedUpgradeCost(3),
    available: rootStore => rootStore.shop.upgrades.peasantMove3.bought,
    subtext: () => peasantSpeedText(3),
  },
  peasantMove5: {
    label: "Superior Peasant Boots V",
    cost: peasantSpeedUpgradeCost(4),
    available: rootStore => rootStore.shop.upgrades.peasantMove4.bought,
    subtext: () => peasantSpeedText(4),
  },
  peasantMove6: {
    label: "Epic Peasant Boots VI",
    cost: peasantSpeedUpgradeCost(5),
    available: rootStore => rootStore.shop.upgrades.peasantMove5.bought,
    subtext: () => peasantSpeedText(5),
  },
  peasantMove7: {
    label: "Heroic Peasant Boots VII",
    cost: peasantSpeedUpgradeCost(6),
    available: rootStore => rootStore.shop.upgrades.peasantMove6.bought,
    subtext: () => peasantSpeedText(6),
  },
  peasantMove8: {
    label: "Heroic Peasant Boots VIII",
    cost: peasantSpeedUpgradeCost(7),
    available: rootStore => rootStore.shop.upgrades.peasantMove7.bought,
    subtext: () => peasantSpeedText(7),
  },
  peasantHarvest: {
    label: "Swift Peasant Knife",
    cost: 100,
    available: rootStore => rootStore.shop.technology.workshop.bought,
    subtext: () => "The Crops won't know what hit em!!",
  },
  donkeyCapacity1: {
    label: "Medium Donkey Cargo Hold I",
    cost: donkeyCapacityUpgradeCost(0),
    available: rootStore =>
      rootStore.world.workers.donkeys.length > 0 &&
      rootStore.shop.technology.factory.bought,
    subtext: () => donkeyCapacityText(0),
  },
  donkeyCapacity2: {
    label: "Large Donkey Cargo Hold II",
    cost: donkeyCapacityUpgradeCost(1),
    available: rootStore => rootStore.shop.upgrades.donkeyCapacity1.bought,
    subtext: () => donkeyCapacityText(1),
  },
  donkeyCapacity3: {
    label: "Massive Donkey Cargo Hold III",
    cost: donkeyCapacityUpgradeCost(2),
    available: rootStore => rootStore.shop.upgrades.donkeyCapacity2.bought,
    subtext: () => donkeyCapacityText(2),
  },
  donkeyCapacity4: {
    label: "Enourmous Donkey Cargo Hold IV",
    cost: donkeyCapacityUpgradeCost(3),
    available: rootStore => rootStore.shop.upgrades.donkeyCapacity3.bought,
    subtext: () => donkeyCapacityText(3),
  },
  donkeyCapacity5: {
    label: "Ridiculous Donkey Cargo Hold V",
    cost: donkeyCapacityUpgradeCost(4),
    available: rootStore => rootStore.shop.upgrades.donkeyCapacity4.bought,
    subtext: () => donkeyCapacityText(4),
  },
  donkeyCapacity6: {
    label: "Gargantuan Donkey Cargo Hold VI",
    cost: donkeyCapacityUpgradeCost(5),
    available: rootStore => rootStore.shop.upgrades.donkeyCapacity5.bought,
    subtext: () => donkeyCapacityText(5),
  },
  donkeyCapacity7: {
    label: "Supercolossal Donkey Cargo Hold VII",
    cost: donkeyCapacityUpgradeCost(6),
    available: rootStore => rootStore.shop.upgrades.donkeyCapacity6.bought,
    subtext: () => donkeyCapacityText(6),
  },
  donkeyMove1: {
    label: "Reasonable Donkey Propulsion I",
    cost: donkeySpeedUpgradeCost(0),
    available: rootStore =>
      rootStore.world.workers.donkeys.length > 0 &&
      rootStore.shop.technology.factory.bought,
    subtext: () => donkeySpeedText(0),
  },
  donkeyMove2: {
    label: "Improved Donkey Propulsion II",
    cost: donkeySpeedUpgradeCost(1),
    available: rootStore => rootStore.shop.upgrades.donkeyMove1.bought,
    subtext: () => donkeySpeedText(1),
  },
  donkeyMove3: {
    label: "Capable Donkey Propulsion III",
    cost: donkeySpeedUpgradeCost(2),
    available: rootStore => rootStore.shop.upgrades.donkeyMove2.bought,
    subtext: () => donkeySpeedText(2),
  },
  donkeyMove4: {
    label: "Advanced Donkey Propulsion IV",
    cost: donkeySpeedUpgradeCost(3),
    available: rootStore => rootStore.shop.upgrades.donkeyMove3.bought,
    subtext: () => donkeySpeedText(3),
  },
  donkeyMove5: {
    label: "Irresponsible Donkey Propulsion V",
    cost: donkeySpeedUpgradeCost(4),
    available: rootStore => rootStore.shop.upgrades.donkeyMove4.bought,
    subtext: () => donkeySpeedText(4),
  },
  donkeyMove6: {
    label: "Majestic Donkey Propulsion VI",
    cost: donkeySpeedUpgradeCost(5),
    available: rootStore => rootStore.shop.upgrades.donkeyMove5.bought,
    subtext: () => donkeySpeedText(5),
  },
  donkeyMove7: {
    label: "God-like Donkey Propulsion VII",
    cost: donkeySpeedUpgradeCost(6),
    available: rootStore => rootStore.shop.upgrades.donkeyMove6.bought,
    subtext: () => donkeySpeedText(6),
  },
}
