import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_HahaFuckingQuest: DialogueConfig = {
  title: music[5].label,
  messages: [
    {
      character: characters.peter,
      text: "*Gorse*! How fortunate it is to have crossed your path.",
    },
    {
      character: characters.gorse,
      text: "Greetings. What a scene of jubilation you exhibit!",
    },
    {
      character: characters.peter,
      text: "Alas, I dance not of my own volition. In truth, I wish to stop.",
    },
    {
      character: characters.arthur,
      text: "Might you\u2013Watcher of Stars and Tracer of Moon\u2013be of assistance?",
    },
    {
      character: characters.gorse,
      text: "The heavens above can guide ships across oceans, and men over mountains. They can answer all manner of queries, foretell future and past\u2026",
    },
    {
      character: characters.action,
      text: "Gorse Paneshaw gazes reverently to the darkness above",
    },
    { character: characters.peter, text: "Well? Can you consult them?" },
    { character: characters.gorse, text: "No. I cannot." },
    { character: characters.arthur, text: "You could try, surely?" },
    {
      character: characters.gorse,
      text: "Sorry, I fear they\u2019re no use in this instance. Besides, it\u2019s too clouded.",
    },
    { character: characters.arthur, text: "Too cloudy?" },
    { character: characters.action, text: "Arthur and Peter look upward" },
    { character: characters.arthur, text: "Curse this milky firmament..." },
    {
      character: characters.peter,
      text: "Gramercy, for your troubles. We\u2019ll find another way.",
    },
    { character: characters.gorse, text: "Trouble not. Trouble not." },
  ],
}
