type Tick = "tick"
type Interrupt = "interrupt"
type Break = "break"
export type Signal = Tick | Interrupt | Break
export const TICK = "tick" as Tick
export const INTERRUPT = "interrupt" as Interrupt
export const BREAK = "break" as Break

export interface BehaviourState {
  timeRemaining: number
}
export type Behaviour = (state: BehaviourState) => Generator<Signal, void, void>

export function* tick(state: BehaviourState) {
  if (state.timeRemaining !== 0)
    throw new Error("wait: still time in the current tick")
  yield TICK
  if (state.timeRemaining === 0)
    throw new Error("wait: didn't get more time after yield")
}

export function* wait(
  state: BehaviourState,
  duration: number,
  interrupt = false
) {
  let timeRemaining = duration
  while (timeRemaining > 0) {
    const step = Math.min(timeRemaining, state.timeRemaining)
    timeRemaining -= step
    state.timeRemaining -= step
    if (interrupt) yield INTERRUPT
    if (state.timeRemaining === 0) yield* tick(state)
  }
}

export function* selector(
  state: BehaviourState,
  getNextBehaviour: () => Behaviour
) {
  let currentBehaviour = getNextBehaviour()
  let iterator = currentBehaviour(state)
  let result = iterator.next()
  while (true) {
    if (result.done) {
      currentBehaviour = getNextBehaviour()
      iterator = currentBehaviour(state)
    } else {
      yield result.value
    }
    if (result.value === INTERRUPT) {
      const nextBehaviour = getNextBehaviour()
      if (nextBehaviour !== currentBehaviour) {
        iterator.return()
        currentBehaviour = nextBehaviour
        iterator = currentBehaviour(state)
      }
    }
    result = iterator.next()
  }
}

// export function* loop<G extends Generator<Break | Rick, any, any>>(
//   generatorFn: () => G,
//   maxTime = 100
// ) {
//   let startTime = Date.now()
//   while (true) {
//     for (const value of generatorFn()) {
//       if (value === BREAK) return
//       yield value
//       if (value === TICK) startTime = Date.now()
//     }
//     if (Date.now() > startTime + maxTime) throw new Error()
//   }
// }

export const coroutine = <Next>(
  generatorFn: () => Generator<any, any, Next>
) => {
  const generator = generatorFn()
  generator.next()
  return (arg: Next) => generator.next(arg)
}

export type BehaviourController = (time: number) => void
export const startBehaviour = (behaviour: Behaviour): BehaviourController =>
  coroutine(function* step() {
    const state: BehaviourState = { timeRemaining: yield }
    const iterator = behaviour(state)
    let result = iterator.next()
    while (!result.done) {
      if (result.value === TICK) {
        state.timeRemaining = yield
        if (state.timeRemaining <= 0)
          throw new Error("Behaviour was not given time")
      }
      result = iterator.next()
    }
    throw new Error("Behaviour ended")
  })
