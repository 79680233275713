const mask = 0xffffffff

const hash = (string: string) => {
  let hash = 0,
    i,
    chr
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return hash
}

export const seededRandom = (seed: number | string = Math.random()) => {
  if (typeof seed === "string") seed = hash(seed)
  let m_w = (123456789 + seed) & mask
  let m_z = (987654321 - seed) & mask
  return (min = 0, max = 1) => {
    m_z = (36969 * (m_z & 65535) + (m_z >> 16)) & mask
    m_w = (18000 * (m_w & 65535) + (m_w >> 16)) & mask
    let result = ((m_z << 16) + (m_w & 65535)) >>> 0
    result /= 4294967296
    return result * (max - min) + min
  }
}
