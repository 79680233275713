import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_RestEasy: DialogueConfig = {
  title: music[4].label,
  messages: [
    {
      character: characters.arthur,
      text: "*Roger*! My most learned of friends. How glad I am to have found you.",
    },
    {
      character: characters.roger,
      text: "Arthur, the pleasure is all mine. And who is this by your side?",
    },
    { character: characters.action, text: "Peter nimbly moves to and fro" },
    { character: characters.arthur, text: "This is Peter Talisman." },
    { character: characters.roger, text: "Greetings Peter." },
    {
      character: characters.arthur,
      text: "He is helping me and now I must ask you to be of help to him.",
    },
    {
      character: characters.roger,
      text: "What need have you for a Physician? What sickness afflicts you?",
    },
    {
      character: characters.peter,
      text: "You see, something has stricken him. To stand still he is unable.",
    },
    {
      character: characters.action,
      text: "Peter performs a most jagged of pirouettes",
    },
    {
      character: characters.peter,
      text: "No matter my will, move to the music I must.",
    },
    {
      character: characters.action,
      text: "Roger observes Peter\u2019s movements",
    },
    {
      character: characters.roger,
      text: "Hmm, indeed. Tis\u2019 a mania of sorts.",
    },
    { character: characters.arthur, text: "So, what say you?" },
    { character: characters.roger, text: "Hot blood." },
    { character: characters.peter, text: "Really?" },
    {
      character: characters.roger,
      text: "A sanguine nature dominates. An overactive liver perhaps. We must rebalance the humors.",
    },
    { character: characters.arthur, text: "And what do you prescribe?" },
    { character: characters.peter, text: "No leeches! I beg of you." },
    { character: characters.roger, text: "Bathe with this." },
    {
      character: characters.action,
      text: "Roger hands Peter a vinegar-soaked rag",
    },
    {
      character: characters.roger,
      text: "And drink a tea of carnations and cloves.",
    },
    { character: characters.arthur, text: "You cannot help him here and now?" },
    {
      character: characters.roger,
      text: "Bar bloodletting? Regrettably not. In this remedy, patience is an indispensable ingredient.",
    },
    { character: characters.arthur, text: "Then we bid you farewell." },
    { character: characters.roger, text: "Hale be thou." },
  ],
}
