import { makeAutoObservable, when } from "mobx"
import { MathUtils } from "three"
import {
  WorldStore,
  ShopStore,
  DialoguesStore,
  UIStore,
  Assets,
  ObjectivesStore,
  WeatherStore,
  PlayerStore,
  MusicStore,
} from "."
import { testMode, triggers } from "../config"

const now = () => Date.now() / 1000
const tick = () => new Promise(requestAnimationFrame)
type UpdateState = { isLongUpdate: boolean; progress: number }

export enum GamePhase {
  NotStarted = 0,
  Intro = 1,
  Tutorial = 2,
  Game = 3,
  Dance = 4,
  Postgame = 5,
}

export class RootStore {
  id = MathUtils.generateUUID()
  assets: Assets
  music: MusicStore
  player: PlayerStore
  world: WorldStore
  shop: ShopStore
  objectives: ObjectivesStore
  dialogues: DialoguesStore
  weather: WeatherStore
  ui: UIStore
  chapter = 0
  gamePhase: GamePhase = GamePhase.NotStarted
  constructor(assets: Assets) {
    if (!assets.loaded) throw new Error("RootStore: assets need to be loaded")
    this.assets = assets
    this.music = new MusicStore()
    this.player = new PlayerStore(this)
    this.world = new WorldStore(this)
    this.shop = new ShopStore(this)
    this.dialogues = new DialoguesStore(this)
    this.objectives = new ObjectivesStore()
    this.ui = new UIStore(this)
    this.weather = new WeatherStore(this)
    makeAutoObservable(this)
    this.init()
  }

  init() {
    this.world.init()
    this.ui.init()
    this.weather.init()
    this.bindTriggers()
  }

  bindTriggers() {
    triggers.forEach(({ condition, action }) => {
      // if (condition(this)) return
      this.disposers.push(
        when(
          () => condition(this),
          () => action(this)
        )
      )
    })
  }

  startGame() {
    this.gamePhase = GamePhase.Intro
  }

  setGamePhase(gamePhase: GamePhase) {
    this.gamePhase = gamePhase
  }

  setChapter(chapter: number) {
    this.chapter = chapter
  }

  timeStep = 1 / 60
  maxFrameTime = 1 / 10
  time = 0
  steps = 0
  step() {
    this.time += this.timeStep
    this.world.step(this.timeStep)
    this.weather.step(this.timeStep)
    this.steps++
  }

  paused = true
  prevTime: number | null = null
  pause() {
    if (this.paused) return
    this.paused = true
    if (this.frame !== null) cancelAnimationFrame(this.frame)
  }
  resume() {
    if (!this.paused) return
    this.paused = false
    this.prevTime = null
    this.run()
  }

  updateState: UpdateState = { isLongUpdate: false, progress: 0 }
  setUpdateState(state: UpdateState) {
    this.updateState.isLongUpdate = state.isLongUpdate
    this.updateState.progress = state.progress
  }

  async advance(time: number) {
    this.setUpdateState({ isLongUpdate: true, progress: 0 })
    const startTime = now()
    let steps = 0
    let frames = 1
    let timeRemaining = time
    let frameStartTime = startTime
    while (timeRemaining >= this.timeStep) {
      this.step()
      timeRemaining -= this.timeStep
      steps++
      const currentTime = now()
      const frameTime = currentTime - frameStartTime
      if (frameTime > this.maxFrameTime) {
        this.setUpdateState({
          isLongUpdate: true,
          progress: 1 - timeRemaining / time,
        })
        await tick()
        frames++
        frameStartTime = now()
      }
    }
    this.setUpdateState({ isLongUpdate: false, progress: 0 })
    const endTime = Date.now() / 1000
    if (false) {
      console.log(
        `advanced ${time - timeRemaining}s (${steps} steps) in ${
          endTime - startTime
        }s over ${frames} frames`
      )
    }
    return timeRemaining
  }
  frame: number | null = null
  timeLeftOver = 0
  run() {
    if (this.prevTime === null) this.prevTime = Date.now() / 1000
    const currentTime = now()
    let timeToAdvance = Math.min(
      this.timeLeftOver + Math.min(currentTime - this.prevTime),
      this.maxFrameTime
    )
    while (timeToAdvance > 0) {
      this.step()
      timeToAdvance -= this.timeStep
    }
    this.prevTime = currentTime
    this.frame = requestAnimationFrame(() => this.run())
  }

  disposers: (() => void)[] = []
  dispose() {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
    this.world.dispose()
    this.ui.dispose()
  }
}
