export const tween = (duration: number, onFrame: (t: number) => void) =>
  new Promise<void>(resolve => {
    const startTime = Date.now()
    const tick = () => {
      const now = Date.now()
      const t = Math.min((now - startTime) / duration, 1)
      onFrame(t)
      if (t === 1) {
        resolve()
      } else {
        requestAnimationFrame(tick)
      }
    }
    tick()
  })
