import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_Outro: DialogueConfig = {
  title: music[11].label,
  messages: [
    {
      character: characters.action,
      text: "A Stillness sweeps over the land.",
    },
    {
      character: characters.action,
      text: "But there is still much left to uncover.",
    },
    {
      character: characters.action,
      text: "Secrets yet to be revealed.",
    },
  ],
}
