import { useRef, useState } from "react"
import {
  Mesh,
  ShaderMaterial,
  UniformsUtils,
  UniformsLib,
  AdditiveBlending,
  Vector2,
} from "three"
import { useStore } from "../../StoreProvider"
import vertexShader from "../../../utils/shaders/fullscreenVertex.glsl"
import fragmentShader from "./rainFragment.glsl"
import { useFrame } from "@react-three/fiber"
import { planeGeometry } from "../../../utils/primitives"
import { useWeather } from "../WeatherProvider"

export const Rain = () => {
  const ref = useRef<Mesh>()
  const rootStore = useStore()
  const { weather } = rootStore
  const weatherUniforms = useWeather()
  const [material] = useState(
    () =>
      new ShaderMaterial({
        vertexShader,
        fragmentShader,
        transparent: true,
        lights: true,
        blending: AdditiveBlending,
        uniforms: {
          ...UniformsUtils.clone(UniformsLib.lights),
          viewMatrixInverse: weatherUniforms.viewMatrixInverse,
          projectionMatrixInverse: weatherUniforms.projectionMatrixInverse,
          time: weatherUniforms.time,
          rain: weatherUniforms.rain,
          viewSize: weatherUniforms.viewSize,
          rainMap: { value: rootStore.assets.textures.rain },
          texSize: {
            value: new Vector2(
              rootStore.assets.textures.rain.image.width,
              rootStore.assets.textures.rain.image.height
            ),
          },
          zoom: { value: 1 },
        },
        depthTest: false,
        depthWrite: false,
      })
  )
  useFrame((state, dT) => {
    const mesh = ref.current
    if (!mesh) throw new Error("Ref not set")
    mesh.visible = weather.rain.currentValue > 0
    material.uniforms.zoom.value = state.camera.zoom
  })

  return (
    <mesh
      ref={ref}
      geometry={planeGeometry}
      material={material}
      frustumCulled={false}
      renderOrder={10}
    />
  )
}
