import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_LeyUDown: DialogueConfig = {
  title: music[9].label,
  messages: [
    {
      character: characters.keith,
      text: "Well, what\u2019s that in the distance? A festival of delights?",
    },
    {
      character: characters.arthur,
      text: "Greetings, *Keith*. Right you are. So many suddenly struck with a desire to dance.",
    },
    {
      character: characters.keith,
      text: "The veil is thinning. Otherworldly sounds and actions intrude. I hear them too.",
    },
    {
      character: characters.arthur,
      text: "You do? Haply you can help us? None so far have been both willing and able.",
    },
    {
      character: characters.keith,
      text: "Sadly not. Even If I were to cast an almighty incantation, the inexorable remains just that.",
    },
    {
      character: characters.arthur,
      text: "For now, I fear Peter might never complete his duty. See.. The corn remains bound to ground in so many places, still.",
    },
    {
      character: characters.keith,
      text: "And what of your mission, Arthur? How fare thee?",
    },
    { character: characters.arthur, text: "My mission?" },
    {
      character: characters.keith,
      text: "You are almost there. Forge ahead; you will find what you are looking for.",
    },
    { character: characters.arthur, text: "And Peter?" },
    {
      character: characters.keith,
      text: "Your fates will align like the hours of the equinox. I assure you.",
    },
  ],
}
