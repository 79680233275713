import { characters, DialogueConfig } from "."
import { music } from "../music"
export const dialogue_TheAbsoluteScene: DialogueConfig = {
  title: music[8].label,
  messages: [
    {
      character: characters.action,
      text: "Around the rock a handful of revellers turn. A carousel of dancers lost in the thrall. One leaps forward from their formation and speaks.",
    },
    {
      character: characters.billy,
      text: "Timely is the hour of your arrival!",
    },
    { character: characters.arthur, text: "To whomst do we speak?" },
    { character: characters.peter, text: "There are more! You dance as I do." },
    {
      character: characters.billy,
      text: "We must turn, turn, turn. Turn until we leave this mortal coil. Join us!",
    },
    { character: characters.peter, text: "Do you hear the instrumentation?" },
    {
      character: characters.billy,
      text: "As if I were playing it myself. Come, trip the light fantastic.",
    },
    {
      character: characters.peter,
      text: "I feel the secret conductor. Its presence grows stronger.",
    },
    { character: characters.billy, text: "Come along now." },
    {
      character: characters.peter,
      text: "Arthur, won\u2019t you join the jig?",
    },
    {
      character: characters.arthur,
      text: "Go ahead Peter, I\u2019ll mind the harvest. Maybe this will cure you of your restless legs.",
    },
    {
      character: characters.action,
      text: "Into the group, Peter joyously jumps. An almighty sight to behold. Bodies bouncing beyond reason.",
    },
  ],
}
