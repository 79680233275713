export type ObjectiveID = "cutCorn" | "returnCorn" | "moveCamera" | "zoomCamera"

export type ObjectiveConfig = {
  message: string
}

export const objectives: Record<ObjectiveID, ObjectiveConfig> = {
  cutCorn: { message: "Cut the crops" },
  returnCorn: { message: "Deposit grain at the granary" },
  moveCamera: { message: "Move around by dragging" },
  zoomCamera: { message: "Zoom in and out by scrolling" },
}
