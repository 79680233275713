import { Box } from "@chakra-ui/react"
import { useState } from "react"

export const loopBorder = {
  borderWidth: "9px",
  borderImageWidth: "44px",
  borderImageSlice: "40 40 40 40",
  borderImageRepeat: "stretch stretch",
  borderImageSource: `url(assets/thinBorder_dither.png)`,
}
export const LoopBorder = (props: React.ComponentProps<typeof Box>) => (
  <Box sx={loopBorder} {...props} />
)

export const thinBorder = {
  borderWidth: "1px",
  borderImageWidth: "123px",
  borderImageSlice: "123 123 123 123",
  borderImageRepeat: "stretch stretch",
  borderImageSource: `url(assets/border-thin.png)`,
}
export const ThinBorder = (props: React.ComponentProps<typeof Box>) => (
  <Box sx={thinBorder} {...props} />
)

export const buttonBorder = {
  borderWidth: "1px",
  borderImageWidth: "12px 48px",
  borderImageSlice: "12 48",
  borderImageRepeat: "stretch stretch",
  borderImageSource: `url(assets/border-thin-250x80.png)`,
}
export const ButtonBorder = (props: React.ComponentProps<typeof Box>) => (
  <Box sx={buttonBorder} {...props} />
)

export const latticeBorder = {
  borderWidth: "9px",
  borderImageWidth: "18px",
  borderImageSlice: "18 18 18 18",
  borderImageRepeat: "stretch stretch",
  borderImageSource: `url(assets/border4.svg)`,
}
export const LatticeBorder = (props: React.ComponentProps<typeof Box>) => (
  <Box sx={latticeBorder} {...props} />
)

export const brokenLatticeBorder = {
  borderWidth: "9px",
  borderImageWidth: "123px",
  borderImageSlice: "123 123 123 123",
  borderImageRepeat: "stretch stretch",
  borderImageSource: `url(assets/border7.png)`,
}
export const BrokenLatticeBorder = (
  props: React.ComponentProps<typeof Box>
) => <Box sx={brokenLatticeBorder} {...props} />

export const Line = (props: React.ComponentProps<typeof Box>) => {
  const [offset] = useState(() => Math.random())
  return (
    <Box
      backgroundImage="url(assets/border-line.png)"
      style={{ backgroundPosition: `${offset * 100}% 0` }}
      height="1px"
      {...props}
    />
  )
}

export const waveformBorder = {
  borderWidth: "1px",
  borderImageWidth: "39px 124px",
  borderImageSlice: "39 124",
  borderImageRepeat: "stretch stretch",
  borderImageSource: `url(assets/border-thin-250x80.png)`,
}
export const WaveformBorder = (props: React.ComponentProps<typeof Box>) => (
  <Box sx={waveformBorder} {...props} />
)
