import { Spring } from "wobble"
import { Color, Vector2 } from "three"
import { GamePhase, RootStore } from "."
import { autorun } from "mobx"
import {
  ColorSpring,
  ValuelessSpringConfig,
  Vector3Spring,
} from "../utils/SpringGroup"
import { makeNoise2D } from "fast-simplex-noise"
import { weather, WeatherConfig } from "../config/weather"
import { clamp } from "../utils/math"

const noise = makeNoise2D()

const springConfig: Partial<ValuelessSpringConfig> = { stiffness: 0.2 }

export class WeatherStore {
  rootStore: RootStore
  ambientColor: ColorSpring
  sunColor: ColorSpring
  sunDirection: Vector3Spring
  specularColor: ColorSpring
  nightLightColor: ColorSpring
  windStrength: Spring
  rain: Spring
  lightningFrequency: Spring
  fogColor: ColorSpring
  fogDensity: Spring
  fogHeight: Spring
  skyColor1: ColorSpring
  skyColor2: ColorSpring
  skyColor3: ColorSpring
  lightningColor = new Color(0xa0a0a0)
  lightning = 0
  wet = 0
  windDirection = new Vector2()
  windOffset = new Vector2()
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    const currentWeather =
      weather[rootStore.world.numClaimedStandingStones] || weather[0]
    this.ambientColor = new ColorSpring({
      ...springConfig,
      fromValue: currentWeather.ambientColor,
    })
    this.sunColor = new ColorSpring({
      ...springConfig,
      fromValue: currentWeather.sunColor,
    })
    this.sunDirection = new Vector3Spring({
      ...springConfig,
      fromValue: currentWeather.sunDirection,
    })
    this.specularColor = new ColorSpring({
      ...springConfig,
      fromValue: currentWeather.specularColor,
    })
    this.nightLightColor = new ColorSpring({
      ...springConfig,
      fromValue: currentWeather.nightLightColor,
    })
    this.windStrength = new Spring({
      ...springConfig,
      fromValue: currentWeather.windStrength,
    })
    this.rain = new Spring({ ...springConfig, fromValue: currentWeather.rain })
    this.lightningFrequency = new Spring({
      ...springConfig,
      fromValue: currentWeather.lightningFrequency,
    })
    this.fogColor = new ColorSpring({
      ...springConfig,
      fromValue: currentWeather.fogColor,
    })
    this.fogHeight = new Spring({
      ...springConfig,
      fromValue: currentWeather.fogHeight,
    })
    this.fogDensity = new Spring({
      ...springConfig,
      fromValue: currentWeather.fogDensity,
    })
    this.skyColor1 = new ColorSpring({
      ...springConfig,
      fromValue: currentWeather.skyColor1,
    })
    this.skyColor2 = new ColorSpring({
      ...springConfig,
      fromValue: currentWeather.skyColor2,
    })
    this.skyColor3 = new ColorSpring({
      ...springConfig,
      fromValue: currentWeather.skyColor3,
    })
  }
  init() {
    autorun(() => {
      let weatherIndex
      if (this.rootStore.gamePhase === GamePhase.Postgame) {
        const currentTrack = this.rootStore.ui.audio.currentTrack
        if (currentTrack === 0) {
          weatherIndex = 1
        } else {
          weatherIndex = currentTrack
        }
      } else {
        weatherIndex = this.rootStore.world.numClaimedStandingStones
      }
      this.setWeather(weather[weatherIndex] || weather[weather.length - 1])
    })
  }
  setWeather(toState: WeatherConfig) {
    this.ambientColor.updateConfig({ toValue: toState.ambientColor }).start()
    this.sunColor.updateConfig({ toValue: toState.sunColor }).start()
    this.sunDirection.updateConfig({ toValue: toState.sunDirection }).start()
    this.specularColor.updateConfig({ toValue: toState.specularColor }).start()
    this.nightLightColor
      .updateConfig({ toValue: toState.nightLightColor })
      .start()
    this.windStrength.updateConfig({ toValue: toState.windStrength }).start()
    this.rain.updateConfig({ toValue: toState.rain }).start()
    this.lightningFrequency
      .updateConfig({ toValue: toState.lightningFrequency })
      .start()
    this.fogColor.updateConfig({ toValue: toState.fogColor }).start()
    this.fogHeight.updateConfig({ toValue: toState.fogHeight }).start()
    this.fogDensity.updateConfig({ toValue: toState.fogDensity }).start()
    this.skyColor1.updateConfig({ toValue: toState.skyColor1 }).start()
    this.skyColor2.updateConfig({ toValue: toState.skyColor2 }).start()
    this.skyColor3.updateConfig({ toValue: toState.skyColor3 }).start()
  }
  step(dT: number) {
    if (Math.random() < 0.01 * this.lightningFrequency.currentValue) {
      this.lightning = 1
    }
    this.lightning = Math.max(this.lightning - 5 * dT, 0)
    this.windDirection.x =
      noise(this.rootStore.time * 0.01, 0) *
      this.windStrength.currentValue *
      0.25
    this.windDirection.y =
      noise(0, this.rootStore.time * 0.01) *
      this.windStrength.currentValue *
      0.25
    this.windOffset.x += this.windDirection.x * dT
    this.windOffset.y += this.windDirection.y * dT
    this.wet += (this.rain.currentValue - 0.05) * dT * (1 / 20)
    this.wet = clamp(this.wet, 0, 1)
  }
}
