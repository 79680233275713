import { characters, DialogueConfig } from "."

export const dialogue_Epilogue: DialogueConfig = {
  title: "Epilogue: Thank You, Pig, For All You Have Done",
  messages: [
    {
      character: characters.action,
      text: "The Subliminal Catalogue is complete.",
    },
  ],
}
