import { when } from "mobx"
import { GamePhase, RootStore } from "../store"
import { dialogues } from "./dialogue"

export interface TriggerConfig {
  condition: (rootStore: RootStore) => boolean
  action: (rootStore: RootStore) => void
}

const waitFor = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
const range = (from: number, to: number) => {
  const range: number[] = []
  for (let i = from; i <= to; i++) range.push(i)
  return range
}

const advanceChapter = async (rootStore: RootStore, chapter: number) => {
  rootStore.music.unlockTrack(chapter)
  rootStore.ui.audio.playTrack(chapter)
  await rootStore.ui.showTitle(chapter).entered()
  await rootStore.dialogues.say(dialogues.chapters[chapter])
  await waitFor(500)
  rootStore.setChapter(chapter)
}

export const triggers: TriggerConfig[] = [
  {
    condition: rootStore => rootStore.gamePhase === GamePhase.Intro,
    action: async rootStore => {
      rootStore.music.unlockTrack(0)
      rootStore.ui.audio.playTrack(0)
      const title = rootStore.ui.showTitle(0)
      await title.entered()
      rootStore.setGamePhase(GamePhase.Tutorial)
      await title.exited()
      rootStore.objectives.add("cutCorn")
    },
  },
  {
    condition: rootStore => rootStore.player.isHeldResourcesFull,
    action: rootStore => {
      rootStore.objectives.complete("cutCorn")
      if (!rootStore.objectives.isComplete("returnCorn"))
        rootStore.objectives.add("returnCorn")
    },
  },
  {
    condition: rootStore => rootStore.player.corn > 0,
    action: async rootStore => {
      rootStore.objectives.complete("cutCorn")
      rootStore.objectives.complete("returnCorn")
      rootStore.setGamePhase(GamePhase.Game)
      await waitFor(2000)
      await rootStore.dialogues.say(dialogues.chapters[0])
    },
  },
  {
    condition: rootStore => rootStore.world.beacon.enabled,
    action: async rootStore => {
      await waitFor(1000)
      rootStore.dialogues.say(dialogues.beacon)
    },
  },
  {
    condition: rootStore => rootStore.world.numClaimedStandingStones === 1,
    action: async rootStore => {
      await advanceChapter(rootStore, 1)
      rootStore.objectives.add("moveCamera")
      rootStore.objectives.add("zoomCamera")
    },
  },
  ...range(2, 9).map(chapter => ({
    condition: (rootStore: RootStore) =>
      rootStore.world.numClaimedStandingStones === chapter,
    action: (rootStore: RootStore) => advanceChapter(rootStore, chapter),
  })),
  {
    condition: rootStore => rootStore.world.numClaimedStandingStones === 10,
    action: async rootStore => {
      rootStore.music.unlockTrack(10)
      rootStore.music.unlockTrack(11)
      rootStore.ui.audio.playTrack(10)
      await rootStore.ui.showTitle(10).entered()
      await rootStore.dialogues.say(dialogues.chapters[10])
      rootStore.setChapter(10)
      rootStore.setGamePhase(GamePhase.Dance)
    },
  },
  {
    condition: rootStore => rootStore.gamePhase === GamePhase.Postgame,
    action: rootStore => {
      rootStore.dialogues.say(dialogues.outro)
    },
  },
  {
    condition: rootStore =>
      Object.values(rootStore.world.loreObjects).every(object => object.isRead),
    action: async rootStore => {
      await rootStore.dialogues.say(dialogues.epilogue)
      rootStore.ui.audio.pause()
      rootStore.ui.setShowVideo(true)
      await when(() => !rootStore.ui.showVideo)
      rootStore.music.unlockTrack(12)
      rootStore.music.unlockTrack(13)
      rootStore.ui.audio.playTrack(12)
    },
  },
]
