import { action, makeObservable, observable } from "mobx"
import { Vector2 } from "three"
import { RootStore } from "."
import { TileMap } from "./TileMap"
import { pathfinder } from "../utils/pathfinder"

export enum TileType {
  Nothing = 2 ** 0,
  Corn = 2 ** 1,
  CutCorn = 2 ** 2,
  Building = 2 ** 3,
}
export const solidTileTypes = TileType.Corn | TileType.Building

const size = new Vector2(600, 600)

export class MapStore extends TileMap<TileType> {
  rootStore: RootStore
  size = size
  center: Vector2
  accessibleTiles = new Set<number>()
  constructor(rootStore: RootStore) {
    super(size)

    this.rootStore = rootStore
    this.center = this.size
      .clone()
      .multiplyScalar(1 / 2)
      .floor()

    this.fill(TileType.Corn)
    this.fillCircle(this.center, 8, TileType.CutCorn)

    makeObservable(this, {
      accessibleTiles: observable,
      onTileChanged: action,
    })

    this.forEach((type, coord) => this.updateAccessible(coord))
    this.addListener(index => this.onTileChanged(this.toCoord(index)))
  }
  isSolid(coord: Vector2 | number, ignoreBuildings = false) {
    const mask = ignoreBuildings
      ? solidTileTypes ^ TileType.Building
      : solidTileTypes
    return this.is(coord, mask)
  }
  checkAccessible(coord: Vector2) {
    if (!this.isSolid(coord)) return false
    let emptyNeighbours = 0
    let emptyOrthogonalNeighbours = 0
    this.getNeighbours(coord, true).forEach(neighbourCoord => {
      const isEmpty =
        this.isInBounds(neighbourCoord) && !this.isSolid(neighbourCoord)
      if (isEmpty) {
        emptyNeighbours++
        const isOrthogonal =
          neighbourCoord.x - coord.x === 0 || neighbourCoord.y - coord.y === 0
        if (isOrthogonal) {
          emptyOrthogonalNeighbours++
        }
      }
    })
    return emptyNeighbours >= 3 && emptyOrthogonalNeighbours >= 1
  }
  updateAccessible(coord: Vector2) {
    const isAccessible = this.checkAccessible(coord)
    const index = this.toIndex(coord)
    if (isAccessible) {
      this.accessibleTiles.add(index)
    } else {
      this.accessibleTiles.delete(index)
    }
  }
  updateAllAccessible() {
    this.forEach((type, coord) => this.updateAccessible(coord))
  }
  onTileChanged(coord: Vector2) {
    this.updateAccessible(coord)
    this.getNeighbours(coord).forEach(neighbourCoord => {
      if (this.isInBounds(neighbourCoord)) this.updateAccessible(neighbourCoord)
    })
  }
  isAccessible(index: Vector2 | number) {
    return this.accessibleTiles.has(
      typeof index === "number" ? index : this.toIndex(index)
    )
  }
  harvest(coord: Vector2) {
    this.set(coord, TileType.CutCorn)
  }
  getPath(from: Vector2, to: Vector2, ignoreBuildings = false) {
    return pathfinder(
      from,
      to,
      this.size,
      coord => !this.isInBounds(coord) || this.isSolid(coord, ignoreBuildings)
    )
  }
}
