import { makeAutoObservable } from "mobx"
import {
  RootStore,
  TileType,
  LoreObjectStore,
  MapStore,
  GranaryStore,
  WorkerManagerStore,
  BeaconStore,
  StandingStoneStore,
  ChalkFigureStore,
} from "."
import { Vector2 } from "three"
import {} from "../config"
import {
  ChalkFigureID,
  chalkFigures,
  StandingStoneID,
  standingStones,
  LoreID,
  loreObjects,
  testMode,
} from "../config"
import { mapObject } from "../utils/object"

export interface IWorldObject {
  id: string
  position: Vector2
  size: Vector2
}

export class WorldStore {
  rootStore: RootStore
  map: MapStore
  beacon: BeaconStore
  workers: WorkerManagerStore
  objects: IWorldObject[] = []
  chalkFigures!: Record<ChalkFigureID, ChalkFigureStore>
  loreObjects!: Record<LoreID, LoreObjectStore>
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.map = new MapStore(this.rootStore)
    this.beacon = new BeaconStore(this.rootStore)
    this.workers = new WorkerManagerStore(this.rootStore)
    makeAutoObservable(this, {
      rootStore: false,
      beacon: false,
      map: false,
      workers: false,
      objects: false,
      chalkFigures: false,
      loreObjects: false,
    })
  }
  init() {
    this.beacon.position = this.map.center.clone()
    this.beacon.enabled = testMode
    this.loadFromSVG()
    if (testMode) {
      for (let i = 0; i < 30; i++) this.workers.createPeasant()
      for (let i = 0; i < 10; i++) this.workers.createDonkey()
    }
    this.workers.init()
    Object.values(this.chalkFigures).forEach(chalkFigure => chalkFigure.init())
  }
  addObject(object: IWorldObject) {
    this.objects = [...this.objects, object]
    if (!(object instanceof StandingStoneStore && !object.isRevealed)) {
      this.map.fillRect(object.position, object.size, TileType.Building)
    }
  }
  get granary() {
    const granary = this.objects.find(
      (object): object is GranaryStore => object instanceof GranaryStore
    )
    if (!granary) throw new Error("no granary")
    return granary
  }
  get standingStones() {
    return this.objects.filter(
      (object): object is StandingStoneStore =>
        object instanceof StandingStoneStore
    )
  }
  get numClaimedStandingStones() {
    return this.standingStones.filter(stone => stone.isClaimed).length
  }
  step(dT: number) {
    this.workers.step(dT)
  }
  loadFromSVG() {
    const svgText = this.rootStore.assets.map.objects
    const svg = new DOMParser().parseFromString(svgText, "image/svg+xml")
    const container = svg.querySelector("#Map")!

    const getRect = (id: string) => {
      const rect = container.querySelector("#" + id)
      if (!rect) throw new Error(`Map is missing ${id}`)
      const attr = (name: string) =>
        Math.floor(Number(rect.getAttribute(name)!))
      return {
        position: new Vector2(attr("x"), attr("y")),
        size: new Vector2(attr("width"), attr("height")),
      }
    }

    const { position: granaryPosition } = getRect("granary")
    const granary = new GranaryStore(this.rootStore, granaryPosition)
    this.addObject(granary)

    this.chalkFigures = mapObject(chalkFigures, (config, id) => {
      const rect = getRect(id)
      return new ChalkFigureStore(
        this.rootStore,
        id,
        rect.position,
        rect.size,
        config
      )
    })

    let standingStoneID: StandingStoneID
    for (standingStoneID in standingStones) {
      const rect = getRect(standingStoneID)
      const standingStone = new StandingStoneStore(
        this.rootStore,
        standingStoneID,
        rect.position,
        standingStones[standingStoneID]
      )
      this.addObject(standingStone)
    }

    this.loreObjects = mapObject(loreObjects, (config, id) => {
      const rect = getRect(id)
      const lorePosition = rect.position.add(new Vector2(1, 1)) // Because the lore object is 1x1 but oin SVG is 3x3
      return new LoreObjectStore(this.rootStore, id, lorePosition, config)
    })
  }
  dispose() {
    this.workers.dispose()
    Object.values(this.chalkFigures).forEach(chalkFigure =>
      chalkFigure.dispose()
    )
  }
}
