import { makeAutoObservable } from "mobx"
import { RootStore } from "."
import {
  workers,
  WorkerID,
  technology,
  TechnologyID,
  upgrades,
  UpgradeID,
  ShopItemConfig,
} from "../config"
import { mapObject } from "../utils/object"
import { testMode } from "../config/game"

export class ShopItemStore {
  id: string
  rootStore: RootStore
  config: ShopItemConfig
  bought = false
  constructor(rootStore: RootStore, id: string, config: ShopItemConfig) {
    this.id = id
    this.rootStore = rootStore
    this.config = config
    makeAutoObservable(this)
  }
  get label() {
    return typeof this.config.label === "function"
      ? this.config.label(this.rootStore)
      : this.config.label
  }
  get cost() {
    return typeof this.config.cost === "function"
      ? this.config.cost(this.rootStore)
      : this.config.cost
  }
  get available() {
    return (
      (this.config.available === undefined ||
        this.config.available(this.rootStore)) &&
      (!this.bought || this.config.buyMultiple)
    )
  }
  get affordable() {
    return this.rootStore.player.corn >= this.cost
  }
  get subtext() {
    if (this.config.subtext) return this.config.subtext(this.rootStore)
    return null
  }
  buy() {
    if (!this.affordable) throw new Error("Can't afford")
    this.rootStore.player.corn -= this.cost
    if (this.config.buy) this.config.buy(this.rootStore)
    this.bought = true
  }
}

export class ShopStore {
  workers: Record<WorkerID, ShopItemStore>
  technology: Record<TechnologyID, ShopItemStore>
  upgrades: Record<UpgradeID, ShopItemStore>
  constructor(rootStore: RootStore) {
    this.workers = mapObject(
      workers,
      (config, id) => new ShopItemStore(rootStore, id, config)
    )
    this.technology = mapObject(
      technology,
      (config, id) => new ShopItemStore(rootStore, id, config)
    )
    this.upgrades = mapObject(
      upgrades,
      (config, id) => new ShopItemStore(rootStore, id, config)
    )
    if (testMode) this.technology.beacon.bought = true
  }
}
