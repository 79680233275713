import { RootStore } from "."
import { Vector2 } from "three"
import { TextureID } from "../config"
import { makeAutoObservable } from "mobx"
import { TileMap } from "./TileMap"
import * as bitmask from "../utils/bitmask"
import { ChalkFigureConfig, ChalkFigureID } from "../config/world"

export enum ChalkFigurePixelState {
  Exposed = 2 ** 0,
  Important = 2 ** 1,
}

const getImportantPixels = (image: HTMLImageElement, size: Vector2) => {
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  if (!ctx) throw new Error("Couldn't get 2d context")
  canvas.width = size.x
  canvas.height = size.y
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
  const pixels = new TileMap(size)
  let count = 0
  for (let i = 0; i < imageData.data.length; i += 4) {
    const alpha = imageData.data[i + 3]
    if (alpha >= 90) {
      pixels.set(Math.floor(i / 4), ChalkFigurePixelState.Important)
      count++
    }
  }
  return { pixels, count }
}

export class ChalkFigureStore {
  id: ChalkFigureID
  rootStore: RootStore
  position: Vector2
  size: Vector2
  textureID: TextureID
  stateMap: TileMap<ChalkFigurePixelState>
  importantPixelCount: number
  exposedImportantPixels = 0
  loaded = false
  constructor(
    rootStore: RootStore,
    id: ChalkFigureID,
    position: Vector2,
    size: Vector2,
    config: ChalkFigureConfig
  ) {
    this.id = id
    this.rootStore = rootStore
    this.position = position
    this.size = size
    this.textureID = config.textureID
    const { pixels, count } = getImportantPixels(
      this.rootStore.assets.textures[this.textureID].image,
      this.size
    )
    this.stateMap = pixels
    this.importantPixelCount = count
    makeAutoObservable(this)
  }
  updateMask(worldCoord: Vector2) {
    const localCoord = worldCoord.clone().sub(this.position)
    if (!this.stateMap.isInBounds(localCoord)) return
    const value = this.stateMap.get(localCoord)
    const isImportant = bitmask.get(value, ChalkFigurePixelState.Important)
    const isExposed = !this.rootStore.world.map.isSolid(worldCoord)
    this.stateMap.set(
      localCoord,
      bitmask.set(value, ChalkFigurePixelState.Exposed, isExposed)
    )
    if (isExposed && isImportant) this.exposedImportantPixels++
  }
  init() {
    const map = this.rootStore.world.map
    map.forEach(
      (value, coord) => this.updateMask(coord),
      this.position,
      this.position.clone().add(this.size)
    )
    const mapListener = (index: number) => this.updateMask(map.toCoord(index))
    map.addListener(mapListener)
    this.disposers.push(() => map.removeListener(mapListener))
  }
  get isExposed() {
    return this.exposedImportantPixels > this.importantPixelCount * 0.95
  }
  disposers: (() => void)[] = []
  dispose() {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }
}
