export type LoreObjectConfig = {
  name: string
  image: string
  text: string
}

export type LoreID =
  | "acousticMoldboard"
  | "electricMouthHarp"
  | "jarOfJelliedEels"
  | "leftHandedSequencer"
  | "rustyCrumhorn"
  | "soiledPsaltery"
  | "tatteredAlmanac"
  | "untunedAxe"
  | "pristeneEtherphone"
  | "noveltyThumbDrive"
  | "mq9ReaperDrone"
  | "bottleOfKrug1928"
  | "greatHelm"
  | "detachedCatalyticConvertor"
  | "subsonicDowsingDevice"
  | "wirelessPrinter"
  | "serverRack"
  | "animatronicToy"
  | "ergonomicVerticalTrackball"
  | "grubbyTicketStub"

export const loreObjects: Record<LoreID, LoreObjectConfig> = {
  acousticMoldboard: {
    name: "Acoustic Moldboard",
    image: "assets/artifacts/acoustic-moldboard.jpeg",
    text: "A flat-bottomed implement with a row of sharp edges extending out from its longest edge. Dirt clings to its metallic teeth.",
  },
  electricMouthHarp: {
    name: "Electric Mouth Harp",
    image: "assets/artifacts/electric-mouth-harp.jpeg",
    text: `A small rectangular free-reed device with a series of tiny air holes. This one requires external amplification if it is to be played.`,
  },
  jarOfJelliedEels: {
    name: "Jar of Jellied Eels",
    image: "assets/artifacts/jellied-eels.jpeg",
    text: `A glass jar filled with the flesh of a slender fish, immersed in a cloudy brine.`,
  },
  leftHandedSequencer: {
    name: "Left-handed Sequencer",
    image: "assets/artifacts/left-handed-sequencer.jpeg",
    text: `A dull copper circuit board held inside a polished brass housing. Lights flicker ominously if one attempts to touch it.`,
  },
  rustyCrumhorn: {
    name: "Rusty Crumhorn",
    image: "assets/artifacts/rusty-crumhorn.jpeg",
    text: `A small iron pipe of cylindrical bore curved upward at the lower end. It’s pierced with finger holes and caked with rust.`,
  },
  soiledPsaltery: {
    name: "Soiled Psaltery",
    image: "assets/artifacts/soiled-psaltery.jpeg",
    text: `A glossy trapezoidal object made from a thin wood. A sticky, repugnant residue coats it's fine horsehair strings.`,
  },
  tatteredAlmanac: {
    name: "Tattered Almanac",
    image: "assets/artifacts/tattered-almanac.jpeg",
    text: `A dog-eared publication printed on yellow parchment. It contains an outdated calendar, yesterday's weather forecasts, seasonal farming suggestions; and other miscellaneous data.`,
  },
  untunedAxe: {
    name: "Untuned Axe",
    image: "assets/artifacts/untuned-axe.jpeg",
    text: `A heavy stone blade attached by one edge to the end of a long ivory handle.`,
  },
  pristeneEtherphone: {
    name: "Pristene Ætherphone",
    image: "assets/artifacts/pristene-etherphone.jpg",
    text: "A relatively small silvery device. A loop antenna curves out from one side, while a proximity sensor protrudes vertically from the other. It looks as though it's never been touched.",
  },
  noveltyThumbDrive: {
    name: "Novelty Thumb Drive",
    image: "assets/artifacts/novelty-thumb-drive.jpg",
    text: "A rubber memory stick with 100MB of flash storage. All its files are corrupted.",
  },
  mq9ReaperDrone: {
    name: "MQ-9 Reaper Drone",
    image: "assets/artifacts/mq9-reaper-drone.jpg",
    text: "An unmanned aerial vehicle capable of remotely controlled or autonomous flight operations. Its batteries are fully depleted and its arsenal spent.",
  },
  bottleOfKrug1928: {
    name: "Bottle of Krug 1928",
    image: "assets/artifacts/bottle-of-krug-1928.jpg",
    text: "Champagne of the finest future vintage. Notes of apricot and honey. Composition: 70% Pinot Noir, 22% Chardonnay, 8% Pinot Meunier.",
  },
  greatHelm: {
    name: "Great Helm",
    image: "assets/artifacts/great-helm.jpg",
    text: "A cylinder of steel with very small openings for ventilation and vision. There is evidence of many battles on the metalwork.",
  },
  detachedCatalyticConvertor: {
    name: "Detached Catalytic Convertor",
    image: "assets/artifacts/detached-catalytic-convertor.jpg",
    text: "A ceramic honeycomb core held inside an irregularly curved steel pipe. The oxides, precious metals, and catalytic materials are all entirely spent and in need of replenishing.",
  },
  subsonicDowsingDevice: {
    name: "Subsonic Dowsing Device",
    image: "assets/artifacts/subsonic-dowsing-device.jpg",
    text: "Experimental tool for locating groundwater, buried metals, ores and minerals, gemstones, oil, gravesites and malign vibrations.",
  },
  wirelessPrinter: {
    name: "ET-4850 Wireless Printer",
    image: "assets/artifacts/wireless-printer.jpg",
    text: 'A battered hunk of black plastic, complete with 8.5" x 14" flatbed scanner. It is missing both cyan and magenta ink cartridges.',
  },
  serverRack: {
    name: "Half-Filled Server Rack",
    image: "assets/artifacts/server-rack.jpg",
    text: "A standard 19-inch server rack cabinet with adjustable mounting rails. It is stacked with several pieces of telecommunications equipment, networking hardware and an item of audiovisual production gear.",
  },
  animatronicToy: {
    name: "Animatronic Cuddly Toy",
    image: "assets/artifacts/animatronic-toy.jpg",
    text: "An animal-shaped ball of synthetic fur, stuffed with a simple robotic skeleton. It does not seem to respond when petted.",
  },
  ergonomicVerticalTrackball: {
    name: "Ergonomic Vertical Trackball",
    image: "assets/artifacts/ergonomic-vertical-trackball.jpg",
    text: "A small pointing device consisting of an exposed ball inside a sensor-filled socket. The protruding sphere is fused in its housing, unable to turn in any direction.",
  },
  grubbyTicketStub: {
    name: "Grubby Ticket Stub",
    image: "assets/artifacts/grubby-ticket-stub.jpg",
    text: 'Half of an old cinema ticket. Greasy finger marks cover the yellowing paper. It reads: "Battleship Potemkin. Screen 2. Row G. Seat 16."',
  },
}
