import { computed, makeObservable, observable } from "mobx"
import { MathUtils, Vector2 } from "three"
import { DonkeyStore, GamePhase, GranaryStore, RootStore, TileType } from "."
import { gameSpeed, getUpgradeValue, UpgradeValues } from "../config"
import { splitPath } from "../utils/pathfinder/splitPath"
import {
  BehaviourController,
  BehaviourState,
  INTERRUPT,
  selector,
  startBehaviour,
  TICK,
  tick,
  wait,
} from "../utils/behaviour"
import { findMin } from "../utils/math"
import assert from "assert-ts"

class NoTargetError extends Error {}
class NoPathError extends Error {}

export type PeasantAnimationState =
  | "idle"
  | "walk"
  | "harvestStart"
  | "harvestEnd"
  | "dance"

export class PeasantStore {
  id = MathUtils.generateUUID()
  rootStore: RootStore
  position: Vector2
  direction = new Vector2(0, -1)
  animationState: PeasantAnimationState = "walk"
  corn = 0
  behaviourController?: BehaviourController
  constructor(rootStore: RootStore, position: Vector2) {
    this.rootStore = rootStore
    this.position = position
    makeObservable(this, {
      position: observable,
      moveSpeed: computed({ keepAlive: true }),
      harvestSpeed: computed({ keepAlive: true }),
      capacity: computed({ keepAlive: true }),
    })
  }
  get manager() {
    return this.rootStore.world.workers
  }
  step(dT: number) {
    if (!this.behaviourController)
      this.behaviourController = startBehaviour(this.behaviourRoot.bind(this))
    this.behaviourController(dT)
  }
  behaviourRoot(state: BehaviourState) {
    const harvest = this.harvest.bind(this)
    const deposit = this.deposit.bind(this)
    const dance = this.dance.bind(this)
    return selector(state, () => {
      if (this.rootStore.gamePhase === GamePhase.Dance) return dance
      return this.corn < this.capacity ? harvest : deposit
    })
  }
  *harvest(state: BehaviourState) {
    let target!: Vector2
    let location!: Vector2
    let claimedTarget = false
    const getTarget = () => {
      if (claimedTarget) return location
      const result = this.manager.getHarvestTargetForPeasant(this)
      if (result === null) throw new NoTargetError()
      target = result.target
      location = result.location
      return location
    }
    try {
      for (const signal of this.pathToDynamic(state, getTarget)) {
        if (!claimedTarget && this.canClaimHarvestTarget(target)) {
          this.manager.claimHarvestTarget(target)
          claimedTarget = true
        }
        if (claimedTarget && !this.manager.canHarvest(target)) {
          this.manager.unclaimHarvestTarget(target)
          claimedTarget = false
        }
        yield signal
      }
      const speed = this.harvestSpeed
      const harvestDuration = 1 / speed
      this.animationState = "harvestStart"
      for (const signal of wait(state, harvestDuration * (1 / 3))) {
        if (!this.manager.canHarvest(target)) return
        yield signal
      }
      this.animationState = "harvestEnd"
      this.rootStore.world.map.harvest(target)
      this.corn++
      yield* wait(state, harvestDuration * (2 / 3))
    } catch (e) {
      yield TICK
    } finally {
      if (claimedTarget && target) this.manager.unclaimHarvestTarget(target)
    }
  }
  *deposit(state: BehaviourState) {
    let target: GranaryStore | DonkeyStore | null = null
    const getTarget = () => {
      const newTarget = this.getDepositTarget()
      if (newTarget !== target) {
        if (target instanceof DonkeyStore) target.cancelReservation(this)
        target = newTarget
      }
      const points = target.getDropOffPoints()
      assert(points.length)
      return findMin(points, point => point.distanceToSquared(this.position))!
    }
    try {
      target = this.getDepositTarget()
      yield* this.pathToDynamic(state, getTarget)
      if (target instanceof GranaryStore) {
        this.rootStore.player.addCorn(this.corn)
        this.corn = 0
      } else if (target instanceof DonkeyStore) {
        if (!target.hasReservation(this)) return
        target.collectFromPeasant(this)
      }
      yield* wait(state, 1 / 5)
    } catch (e) {
      yield TICK
    } finally {
      if (target instanceof DonkeyStore) target.cancelReservation(this)
    }
  }
  *dance(state: BehaviourState) {
    try {
      yield* this.pathToDynamic(state, () => {
        const location = this.manager.danceLocations.get(this)
        if (!location) throw new NoTargetError()
        return location
      })
      this.animationState = "dance"
      yield TICK
    } catch (e) {
      yield TICK
    }
  }
  getDepositTarget() {
    const targets = [
      this.rootStore.world.granary,
      ...this.manager.donkeys.filter(donkey => donkey.altitude === 0),
    ].sort(
      (a, b) =>
        a.position.distanceToSquared(this.position) -
        b.position.distanceToSquared(this.position)
    )
    for (const target of targets) {
      if (target instanceof GranaryStore) return target
      if (target.requestReservation(this)) return target
    }
    throw new NoTargetError()
  }
  *moveTo(state: BehaviourState, to: Vector2) {
    if (this.position.equals(to)) return
    const from = this.position.clone()
    const length = from.distanceTo(to)
    this.direction = to.clone().sub(from).normalize()
    this.animationState = "walk"
    const moveSpeed = this.moveSpeed
    let distanceTravelled = 0
    let done = false
    try {
      while (distanceTravelled < length) {
        const stepDistance = Math.min(
          state.timeRemaining * moveSpeed * gameSpeed,
          length - distanceTravelled
        )
        state.timeRemaining -= stepDistance / moveSpeed
        distanceTravelled += stepDistance
        this.position = new Vector2().lerpVectors(
          from,
          to,
          distanceTravelled / length
        )
        if (!state.timeRemaining) yield* tick(state)
      }
      this.animationState = "idle"
      done = true
    } finally {
      if (!done)
        throw new Error("moveTo did not complete, peasant is in bad position")
    }
  }
  *pathTo(state: BehaviourState, to: Vector2) {
    const map = this.rootStore.world.map
    let path: Vector2[] | null = null
    let mapVersion = 0
    while (!path || path.length > 0) {
      if (!path) {
        path = map.getPath(this.position, to)
        if (path === null) throw new Error("no path")
        path = splitPath(path)
        path.shift()
        mapVersion = map.version
      }
      yield* this.moveTo(state, path[0])
      path.shift()
      yield INTERRUPT
      if (mapVersion !== map.version) path = null
    }
  }
  *pathToDynamic(state: BehaviourState, getDestination: () => Vector2) {
    let destination = getDestination()
    while (!this.position.equals(destination)) {
      for (const signal of this.pathTo(state, destination)) {
        yield signal
        if (signal === INTERRUPT) {
          const nextDestination = getDestination()
          if (!nextDestination.equals(destination)) {
            destination = nextDestination
            break
          }
        }
      }
    }
  }
  canClaimHarvestTarget(target: Vector2) {
    if (this.manager.isHarvestTargetClaimed(target)) return false
    // when cutting through corn toward the beacon,
    // less eager claiming keeps everyone moving.
    // when clearing a space around the beacon,
    // more eager claiming keeps everyone spread out.
    const minDistance = this.rootStore.world.beacon.isAccessible ? 10 : 6
    return target.distanceTo(this.position) <= minDistance
  }
  static moveSpeedUpgrades: UpgradeValues = {
    base: 4,
    upgrades: [
      { id: "peasantMove1", value: 5 },
      { id: "peasantMove2", value: 7 },
      { id: "peasantMove3", value: 9 },
      { id: "peasantMove4", value: 11 },
      { id: "peasantMove5", value: 13 },
      { id: "peasantMove6", value: 15 },
      { id: "peasantMove7", value: 18 },
      { id: "peasantMove8", value: 25 },
    ],
  }
  static capacityUpgrades: UpgradeValues = {
    base: 1,
    upgrades: [
      { id: "peasantCapacity1", value: 2 },
      { id: "peasantCapacity2", value: 3 },
      { id: "peasantCapacity3", value: 4 },
      { id: "peasantCapacity4", value: 6 },
      { id: "peasantCapacity5", value: 8 },
      { id: "peasantCapacity6", value: 12 },
      { id: "peasantCapacity7", value: 16 },
    ],
  }
  static harvestSpeedUpgrades: UpgradeValues = {
    base: 4,
    upgrades: [{ id: "peasantHarvest", value: 20 }],
  }
  get moveSpeed() {
    return getUpgradeValue(this.rootStore, PeasantStore.moveSpeedUpgrades)
  }
  get harvestSpeed() {
    return getUpgradeValue(this.rootStore, PeasantStore.harvestSpeedUpgrades)
  }
  get capacity() {
    return getUpgradeValue(this.rootStore, PeasantStore.capacityUpgrades)
  }
}
