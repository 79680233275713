import { Vector2 } from "three"
import Heap from "heap"
import { stringPull, lineOfSight } from "./stringPull"
import { orthogonalNeighbours } from "../tiles"

// https://briangrinstead.com/blog/astar-search-algorithm-in-javascript/

type PathfinderNode = {
  coord: Vector2
  g: number // cost to travel to this node from start
  h: number // estimated cost to travel from this node to end
  f: number // g + h
  visited: boolean
  closed: boolean
  parent: PathfinderNode | null
}

const neighbourCoord = new Vector2()

export const pathfinder = (
  start: Vector2,
  end: Vector2,
  size: Vector2,
  isSolid: (coord: Vector2) => boolean
) => {
  const nodes: PathfinderNode[] = []
  const getNode = (coord: Vector2) => {
    const { x, y } = coord
    const index = y * size.x + x
    let node = nodes[index]
    if (!node) {
      const h = coord.distanceToSquared(end)
      node = nodes[index] = {
        coord: coord.clone(),
        g: 0,
        h,
        f: h,
        visited: false,
        closed: false,
        parent: null,
      }
    }
    return node
  }

  if (isSolid(start) || isSolid(end)) return null

  if (start.equals(end) || lineOfSight(start, end, isSolid)) return [start, end]

  const heap = new Heap<PathfinderNode>((a, b) => a.f - b.f)
  heap.push(getNode(start))

  while (heap.size() > 0) {
    const currentNode = heap.pop()!
    if (currentNode.coord.equals(end)) {
      let node: PathfinderNode | null = currentNode
      const points: Vector2[] = []
      while (node) {
        points.unshift(node.coord)
        node = node.parent
      }
      return stringPull(points, isSolid)
    }

    for (const neighbourOffset of orthogonalNeighbours) {
      neighbourCoord.copy(currentNode.coord).add(neighbourOffset)

      // allow moving out of solid, but not in
      if (isSolid(neighbourCoord)) continue

      const neighbour = getNode(neighbourCoord)

      if (neighbour.closed) continue
      const g = currentNode.g + 1
      const { visited } = neighbour
      if (!visited || g < neighbour.g) {
        neighbour.visited = true
        neighbour.parent = currentNode
        neighbour.g = g
        neighbour.f = neighbour.g + neighbour.h
        if (!visited) {
          heap.push(neighbour)
        } else {
          heap.updateItem(neighbour)
        }
      }
    }
    currentNode.closed = true
  }
  return null
}
