import { Vector2 } from "three"

const coord = new Vector2()
// https://playtechs.blogspot.com/2007/03/raytracing-on-grid.html
export const lineOfSight = (
  from: Vector2,
  to: Vector2,
  isSolid: (v: Vector2) => boolean
) => {
  let dx = Math.abs(to.x - from.x)
  let dy = Math.abs(to.y - from.y)
  let n = 1 + dx + dy
  const x_inc = to.x > from.x ? 1 : -1
  const y_inc = to.y > from.y ? 1 : -1
  let error = dx - dy
  let x = from.x
  let y = from.y
  dx *= 2
  dy *= 2
  while (--n >= 0) {
    coord.set(x, y)
    if (isSolid(coord)) return false
    if (error > 0) {
      x += x_inc
      error -= dy
    } else {
      y += y_inc
      error += dx
    }
  }
  return true
}

// https://www.gamedev.net/forums/topic/539575-string-pulling-explained/
export const stringPull = (
  inPoints: Vector2[],
  isSolid: (point: Vector2) => boolean
) => {
  if (inPoints.length <= 2) return inPoints
  const points = [...inPoints]
  let start = 0
  while (start < points.length - 2) {
    if (lineOfSight(points[start], points[start + 2], isSolid)) {
      points.splice(start + 1, 1)
    } else {
      start++
    }
  }
  return points
}
