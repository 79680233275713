import { characters, DialogueConfig } from "."
import { music } from "../music"

export const dialogue_OneForTheRoad: DialogueConfig = {
  title: music[6].label,
  messages: [
    {
      character: characters.arthur,
      text: "Why if it isn\u2019t *Tim Wheatsheaf*.",
    },
    {
      character: characters.action,
      text: "Tim gargles and grumbles as he registers his visitors",
    },
    {
      character: characters.arthur,
      text: "Ha. You drunken fool, how kind it is to see you.",
    },
    {
      character: characters.tim,
      text: "Drunk...? Beshrew thee! I am no drunk. I am the chief brewer...",
    },
    {
      character: characters.arthur,
      text: "...and taster I might add! Two truths, that bare none upon the other.",
    },
    {
      character: characters.tim,
      text: "I can\u2019t peddle goods that\u2019ve not been tested for quality, now can I?",
    },
    { character: characters.peter, text: "Not with a clear conscience, no." },
    {
      character: characters.action,
      text: "Tim tries to focus his vision on Peters frantic figure",
    },
    {
      character: characters.tim,
      text: "What\u2019s with you? Why do you cut capers before me?",
    },
    {
      character: characters.peter,
      text: "I hear sounds indescribable. Ones that usher me to act so. Surely you of all people hear them too?",
    },
    {
      character: characters.tim,
      text: "Seems you\u2019re the one that\u2019s been at the mead, sir.",
    },
    { character: characters.peter, text: "Truth be told I haven\u2019t." },
    {
      character: characters.tim,
      text: "Then some you need. Tis\u2019 a cure for all ailments. Take this.",
    },
    {
      character: characters.action,
      text: "Tim hands over a clay pitcher half full with a frothy slurry",
    },
    { character: characters.peter, text: "I thank thee." },
    {
      character: characters.tim,
      text: "No mind. The hour is late. I ought to couch a hogshead.",
    },
    { character: characters.arthur, text: "Rest Tim. Rest." },
  ],
}
