import { makeAutoObservable } from "mobx"
import { MathUtils, Vector2 } from "three"
import { IWorldObject, RootStore, TileType } from "."

export class BeaconStore implements IWorldObject {
  id = MathUtils.generateUUID()
  rootStore: RootStore
  enabled = false
  position = new Vector2()
  size = new Vector2(1, 1)
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
  setEnabled(enabled: boolean) {
    this.enabled = enabled
  }
  setPosition(position: Vector2) {
    this.position = position
  }
  get isAccessible() {
    return !this.rootStore.world.map.isSolid(this.position, true)
  }
  get isInCorn() {
    return this.rootStore.world.map.get(this.position) === TileType.Corn
  }
}
