import Analytics from "analytics"
import doNotTrack from "analytics-plugin-do-not-track"
import googleAnalytics from "@analytics/google-analytics"
// @ts-ignore
import googleAnalyticsV3 from "@analytics/google-analytics-v3"
import { GamePhase, RootStore } from "../store"
import { reaction, when } from "mobx"
import { ObjectiveID, objectives } from "../config/objectives"

export const analytics = Analytics({
  app: "peter-talisman",
  plugins: [
    doNotTrack(),
    googleAnalytics({
      measurementIds: ["G-M385DPPC1G"],
      anonymizeIp: true,
      
    }),
    googleAnalyticsV3({
      trackingId: "UA-211990819-1",
      anonymizeIp: true,
    }),
  ],
})

analytics.page()

export const bindAnalytics = (rootStore: RootStore) => {
  // const getGameState = () => ({
  //   time: rootStore.time,
  //   phase: GamePhase[rootStore.gamePhase],
  //   corn: rootStore.player.corn,
  //   yield: rootStore.player.yield,
  //   stones: rootStore.world.standingStones.reduce((obj, stone) => {
  //     obj[stone.id] = stone.track
  //     return obj
  //   }, {} as Record<StandingStoneID, number | null>),
  //   lore: mapObject(rootStore.world.loreObjects, object => object.isRead),
  //   peasants: rootStore.world.workers.peasants.length,
  //   donkeys: rootStore.world.workers.donkeys.length,
  // })
  const disposers: (() => void)[] = []
  disposers.push(
    reaction(
      () => rootStore.gamePhase,
      () => {
        analytics.track("game phase changed", {
          label: GamePhase[rootStore.gamePhase],
        })
      }
    ),
    ...rootStore.world.standingStones.map(stone =>
      when(
        () => stone.isAccessible,
        () =>
          analytics.track("exposed stone", {
            label: stone.id,
          })
      )
    ),
    ...rootStore.world.standingStones.map(stone =>
      when(
        () => stone.isClaimed,
        () =>
          analytics.track("claimed stone", {
            label: stone.id,
          })
      )
    ),
    ...Object.values(rootStore.world.loreObjects).map(object =>
      when(
        () => object.isFound,
        () =>
          analytics.track("found lore", {
            label: object.id,
          })
      )
    ),
    ...Object.values(rootStore.world.loreObjects).map(object =>
      when(
        () => object.isRead,
        () =>
          analytics.track("read lore", {
            label: object.id,
          })
      )
    ),
    reaction(
      () => rootStore.world.workers.peasants.length,
      () =>
        analytics.track("bought peasant", {
          value: rootStore.world.workers.peasants.length,
        })
    ),
    reaction(
      () => rootStore.world.workers.donkeys.length,
      () =>
        analytics.track("bought donkey", {
          value: rootStore.world.workers.donkeys.length,
        })
    ),
    ...Object.values({
      ...rootStore.shop.upgrades,
      ...rootStore.shop.technology,
    }).map(shopItem =>
      when(
        () => shopItem.bought,
        () =>
          analytics.track("bought upgrade", {
            label: shopItem.id,
          })
      )
    ),
    ...(Object.keys(objectives) as ObjectiveID[]).map(id =>
      when(
        () => rootStore.objectives.isComplete(id),
        () =>
          analytics.track("complete objective", {
            label: id,
          })
      )
    ),
    reaction(
      () => Math.floor(rootStore.player.yield / 1000),
      corn10k => {
        if (corn10k === 0) return
        analytics.track("harvested corn", {
          value: corn10k * 10000,
        })
      }
    )
  )
  return () => disposers.forEach(dispose => dispose())
}
